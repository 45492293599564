const news = [
  {
    id: "1",
    type: "img",
    img: "gps-on-vermont-medical-centre-news.jpg",
    pageLink: "gps-on-vermont-opening-soon",
    title: "GPs on Vermont is Opening Soon",
    contents: [
      "We are so excited to announce that GP's on Vermont is openning soon in Wodonga! We are very excited to be able to offer bulk-billed consultations and exceptional care to the Wodonga community.",
      "Come and discuss your healthcare needs or concerns with us we are ready and willing to assist you.",
      "Make sure you come visit us and like our page for more updates."
    ],
    date: "2020-02-01",
    enabled: true
  },
  {
    id: "2",
    type: "img",
    img: "flu-vaccines-news.jpg",
    pageLink: "flu-vaccination",
    title: "Flu Vaccination",
    contents: [
      "Flu vaccines will be in stock in late March, but we are recommending that you wait until May before being vaccinated. Our flu vaccination clinic will be run by our practice nurses. You can receive a flu vaccine during the course of your consultation with your doctor if you prefer.",
      "People eligible for free influenza vaccine: All children aged six months to less than five years - Victorian government funded. All Aboriginal and Torres Strait Islander people aged from six months. All adults aged ≥65 years"
    ],
    date: "2020-02-10",
    enabled: true
  },
  {
    id: "3",
    type: "img",
    img: "covid-19-news.jpg",
    pageLink: "coronavirus-information",
    title: "Coronavirus",
    contents: [
      "The Department of Health and Human Services is working around the clock with health services, the Commonwealth and international agencies to respond to the outbreak of coronavirus disease (COVID-19) and to keep the Victorian community safe",
      "On this webpage you can find information and guidance about the current outbreak of the coronavirus disease (COVID-19). This is a rapidly changing situation, so please visit this webpage for regular updates.",
      "Coronaviruses are a large family of viruses which may cause illness in animals or humans. In humans, several coronaviruses are known to cause respiratory infections ranging from the common cold to more severe diseases. COVID-19 is the most recently discovered coronavirus.",
      "If you suspect you may have the coronavirus disease (COVID-19), please call the dedicated hotline on 1800 675 398. Please keep Triple Zero (000) for emergencies only."
    ],
    date: "2020-02-12",
    enabled: true
  },
  {
    id: "4",
    type: "img",
    img: "covid-19-protect-yourself-news.jpg",
    pageLink: "coronavirus-covid-19-protect-yourself",
    title: "Coronavirus COVID-19 - How can you best protect yourself and your loved ones?",
    contents: [
      "The best protection is to wash your hands!",
      "Regular hand cleansing is the single most effective action to prevent infections by killing viruses and other germs that may be on your hands.",
      "Effective hand cleansing means washing your hands for 20secs in warm, soapy water and drying them thoroughly.",
      "Or use a alcohol-based hand sanitizer, that contains at least 60% alcohol, if you don’t have immediate access to soap and water.",
      "To watch the World Health Organization (WHO) hand washing video please click - https://youtu.be/3PmVJQUCm4E"
    ],
    date: "2020-02-19",
    enabled: true
  },
  {
    id: "5",
    type: "img",
    img: "covid-19-wearing-masks-news.jpg",
    pageLink: "how-to-use-surgical-face-masks",
    title: "When and how to use surgical face masks",
    contents: [
      "Most members of the general public don’t need to wear masks!",
      "At this stage If you are healthy, you only need to wear a mask if:",
      "1. You have cold or flu symptoms and are going to be around other people.",
      "2. You might be infected with Corona Virus (COVID-19) and are in contact with other people.",
      "3. You are a are taking care of a person with suspected COVID-19 or another contagious infection.",
      "However, if you want to wear a mask to feel like you are more protected you can certainly do so.",
      "Masks are effective only when used in combination with frequent hand-cleaning with alcohol-based hand rub or soap and water. If you wear a mask, then you must know how to use it and dispose of it properly.",
      "Please watch the following face mask video on how to correctly wear a face mask - https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public/when-and-how-to-use-masks"
    ],
    date: "2020-03-04",
    enabled: true
  },
  {
    id: "6",
    type: "img",
    img: "covid-19-symptoms-news.jpg",
    pageLink: "coronavirus-symptoms",
    title: "Coronavirus - Local Update",
    contents: [
      "What are the symptoms and what should you do if you feel unwell?",
      "Corona virus symptoms include fever, flu-like symptoms such as coughing, sore throat and fatigue, runny nose and shortness of breath.",
      "Call us if you have cold or flu type symptoms or concerns about exposure to coronavirus we can help determine if you need to be tested.",
      "Please note you should not attend either the Albury or Wodonga emergency departments without calling first unless you are critically unwell.",
      "If necessary Corona Virus screening appointments can be made at Albury Wodonga Health by calling (02) 6058 4444.",
      "If you have symptoms or are seeking information on coronavirus (COVID-19), call the National Coronavirus Helpline on 1800 020 080. The line operates 24 hours a day, seven days a week.",
      "If you have serious symptoms such as difficulty breathing, call 000 for urgent medical help.",
      "Find more information here: https://www.health.gov.au/news/health-alerts/novel-coronavirus-2019-ncov-health-alert"
    ],
    date: "2020-03-25",
    enabled: true
  },
  {
    id: "7",
    type: "img",
    img: "covid-19-isolation-tips-news.jpg",
    pageLink: "covid-19-self-isolation-tips",
    title: "Self Isolation Tips",
    contents: [
      "It's important to stay at home or in your hotel and don’t go to public places including work, school, childcare, university or public gatherings to stop coronavirus spreading.",
      "Do:",
      "  • Try to keep at least 2 metres (3 steps) from other people in your home, particularly people over 70, or those with long-term health conditions.",
      "  • Ask friends, family and delivery services to deliver things like food shopping and medicines but leave them outside.",
      "  • Sleep alone, if possible.",
      "  • Regularly wash your hands with soap and warm water for at least 20 seconds.",
      "  • Drink plenty of water and take everyday painkillers, such as paracetamol and ibuprofen, to help with your symptoms.",
      "Don’t:",
      "  • Do not have visitors (ask people to leave deliveries outside).",
      "  • Do not leave your home, for example to go for a walk, to school or to public places.",
      "  • If you must leave home, such as to seek medical care, wear a surgical mask. If you don’t have a mask, take care to not cough or sneeze on others.",
      "Read more advice about staying at home on https://www.health.gov.au/sites/default/files/documents/2020/03/coronavirus-covid-19-isolation-guidance_2.pdf."
    ],
    date: "2020-03-19",
    enabled: true
  },
  {
    id: "8",
    type: "img",
    img: "yes-we-are-open-news.jpg",
    pageLink: "gps-on-vermont-we-are-open",
    title: "Gwambunna and Welcome",
    contents: [
      "We are delighted to announce that GPs on Vermont Medical Centre is now open! We are very excited to be able to offer bulk-billed consultations and exceptional care to the Albury Wodonga community.",
      "Come and discuss your healthcare needs or concerns with us we are ready and willing to assist you.",
      "Call one of our friendly staff on (02) 6025 2189 to make an appointment or pop into the clinic during opening hours. We are located at 80 Vermont St, Wodonga VIC 3690",
      "We look forward to meeting you :) "
    ],
    date: "2020-03-18",
    enabled: true
  },
  {
    id: "9",
    type: "img",
    img: "telehealth-news.jpg",
    pageLink: "gpv-telehealth-news",
    title: "TeleHEALTH is Available!!!",
    contents: [
      "We are pleased to announce that some consultations are now available using regular calls or skype. Please don't forget to call us after your booking has been arranged to have everything set up for you.",
      "1.- Consultations will now be conducted over the phone, or video call for patients and includes:",
      " •	All Australians",
      " •	Mental Health",
      " •	Chronic disease Management",
      " •	Aboriginal and Torres Strait Islander Health Assessments (no other HA) ",
      "Important notice: after making your booking do not forget to call us ( 02 6025 2189 or 0477 413 700 ) to let us know you want to use teleHealth, so we can get everything set up for you!",
      ".",
      "As mentioned by the department of health:",
      "To provide continued access to essential primary health services during the COVID-19 pandemic, the Australian Government is expanding Medicare-subsidised telehealth services for all Australians and providing extra incentives to general practitioners and other health practitioners. We are making telehealth a key weapon in the fight against the COVID-19 pandemic. Expanding the consultation services available by telehealth is the next critical stage in the Government’s response to COVID-19.",
      "Services will include GP services and some consultation services provided by other medical specialists, nurse practitioners, mental health treatment, chronic disease management, Aboriginal and Torres Strait Islander health assessments, services to people with eating disorders, pregnancy support counselling, services to patients in aged care facilities, children with autism, and after-hours consultations.",
      "These changes represent the fourth stage of a series of primary care measures and will commence from 8am Monday 30 March. ",
      "More information can be found here: https://www.health.gov.au/ministers/the-hon-greg-hunt-mp/media/covid-19-whole-of-population-telehealth-for-patients-general-practice-primary-care-and-other-medical-services"
    ],
    date: "2020-03-26",
    enabled: true
  },
  {
    id: "10",
    type: "img",
    img: "book-your-flu-shot-news.jpg",
    pageLink: "book-your-flu-shot",
    title: "We've got you covered this winter - Get your Flu vaccine",
    contents: [
      "It's that time again, Flu shots are in!. Everyone can benefit from the flu vaccine, but it’s crucial for people in certain groups.",
      "Getting the flu puts you at risk of secondary infections and serious complications, especially if you’re in a high-risk group. Possible complications include: ",
      " •	pneumonia •	bronchitis • sinus infections •	ear infections ",
      "The very young have an increased chance of developing complications from the flu. It’s important for them to stay up to date on their flu vaccinations. It’s also important to make the flu vaccine a priority if you:",

      " •	are 65 or older",
      " •	live in a nursing home or care facility",
      " •	have a chronic health condition, such as diabetes, asthma, heart disease, or HIV",
      " •	have a weakened immune system",
      " •	are a healthcare worker who may be exposed to people who are sick",
      "Also remember If you are over 65, you might need your PNEUMONIA Vaccine."
    ],
    date: "2020-03-27",
    enabled: true
  },
  {
    id: "11",
    type: "img",
    img: "pneumonia-vaccine-news.jpg",
    pageLink: "book-your-pneumonia-vaccine-news",
    title: "Get your Pneumonia vaccine",
    contents: [
      "We've got Vaccines available to reduce the risk of pneumococcal disease and immunisation. It is recommended for people in high-risk groups.",
      "What is pneumococcal disease?",
      "Pneumococcal disease is caused by infection with the bacteria Streptococcus pneumoniae. Infection can cause a variety of diseases including: pneumonia (infection of the lungs), otitis media (infection of the middle ear) and meningitis (infection of the membranes around the brain and spinal cord).",
      "What are the symptoms?",
      "Symptoms depend on the site of infection and the age of the person, as described below:",
      " •	Pneumonia can cause shortness of breath, fever, lack of energy, loss of appetite, headache, chest pain and cough.",
      " •	Otitis media can cause crying, tugging at the ear, fever, irritability, poor hearing, and sometimes diahorrea and vomiting.",
      " •	Meningitis can cause fever, headache, stiff neck, nausea, vomiting, and drowsiness.",
      "The very young have an increased chance of developing complications from the flu. It’s important for them to stay up to date on their flu vaccinations. It’s also important to make the flu vaccine a priority if you:",
      " •	people aged 65 years and older",
      " •	Aboriginal and Torres Strait Islander people aged 50 and over. It is also available for those aged 15-49 years who have one or more of the following medical risk factors:",
      " -•	heart, kidney or lung disease",
      " -•	asthma or diabetes",
      " -•	are immuno-compromised",
      " -•	are heavy drinkers or smokers."
    ],
    date: "2020-03-26",
    enabled: true
  },
  {
    id: "12",
    type: "img",
    img: "gps-on-vermont-medical-centre-news.jpg",
    pageLink: "covid-19-clinic-process-news",
    title: "COVID-19 Clinic Process",
    contents: [
      "As per response to the Federal Government announcement, we are changing the way that we see patients due to the COVID-19 Pandemic.",
      "1.- Patients displaying potential COVID-19 symptoms such as:",
      " •	Fever > 38",
      " •	Persistent cough",
      " •	Shortness of breath",
      " •	Fatighe",
      "Please call the centre to discuss your next steps. Please do not come to the centre without having discussed your symptoms over the phone with us first. We do not want to put others at possible risk.",
      ".",
      "2.- We will be restricting the number of patients in our waiting room to no more than two patients at any one time.",
      "It is important to note all patients presenting to the practice, in order to limit cross-contamination risk, will be required to wait outside in the parking lot and will be called in when it is their turn. When entering the premises, you will be asked to go straight to the bathroom to wash your hands, wear a mask and will then go straight to your consultation room. Your practitioner will be disinfecting the room between each patient and will also be wearing a mask.",
      ".",
      "We thank you for your cooperation and apologise for any inconvenience."
    ],
    date: "2020-03-26",
    enabled: true
  },
  {
    id: "13",
    type: "img",
    img: "covid-19-covidsafe-news.jpg",
    pageLink: "covid-safe-app-released-news",
    title: "COVIDSafe app realeased!",
    contents: [
      "The COVIDSafe app is part of the government work to slow the spread of COVID-19. Having confidence we can find and contain outbreaks quickly will mean governments can ease restrictions while still keeping Australians safe.",
      "The new COVIDSafe app is completely voluntary. Downloading the app is something you can do to protect you, your family and friends and save the lives of other Australians. The more Australians connect to the COVIDSafe app, the quicker we can find the virus.",      
      "The COVIDSafe app is the only contact trace app approved by the Australian Government.",
      " - ",
      "How COVIDSafe works?",
      "When you download the app you provide your name, mobile number, and postcode and select your age range (see Privacy). You will receive a confirmation SMS text message to complete installation. The system then creates a unique encrypted reference code just for you.",
      "COVIDSafe recognises other devices with the COVIDSafe app installed and Bluetooth enabled. When the app recognises another user, it notes the date, time, distance and duration of the contact and the other user’s reference code. The COVIDSafe app does not collect your location.",
      "To be effective, you should have the COVIDSafe app running as you go about your daily business and come into contact with people. Users will receive daily notifications to ensure the COVIDSafe app is running. The information is encrypted and that encrypted identifier is stored securely on your phone. Not even you can access it. The contact information stored in people’s mobiles is deleted on a 21-day rolling cycle. This period takes into account the COVID-19 incubation period and the time it takes to get tested. For more, see Privacy.",
      "Watch the following video made by ABC news to better understand how the app tracing works: https://abcmedia.akamaized.net/news/video/202004/NOLs_AppPKG_2604_1000k.mp4",
      " - ",
      "When someone is diagnosed with COVID-19, state and territory health officials will ask them or their parent/guardian who they have been in contact with. If they have the COVIDSafe app and provide their permission, the encrypted contact information from the app will be uploaded to a highly secure information storage system. State and territory health officials will then:",
      "use the contacts captured by the app to support their usual contact tracing",
      " •	call people to let them or their parent/guardian know they may have been exposed",
      " •	offer advice on next steps, including:",
      " • •	what to look out for",
      " • •	when, how and where to get tested",
      " • •	what to do to protect friends and family from exposure",
      "Health officials will not name the person who was infected.",
      "-",
      "After the pandemic",
      "At the end of the Australian COVID-19 pandemic, users will be prompted to delete the COVIDSafe app from their phone. This will delete all app information on a person’s phone. The information contained in the information storage system will also be destroyed at the end of the pandemic.",
      "-",
      "Download the app from:",
      "Google Play: https://play.google.com/store/apps/details?id=au.gov.health.covidsafe",
      "App Store: https://apps.apple.com/au/app/covidsafe/id1509242894"
    ],
    date: "2020-04-27",
    enabled: true
  },
  {
    id: "14",
    type: "img",
    img: "gps-on-vermont-medical-centre-news.jpg",
    pageLink: "welcome-dr-angela-ramsay-news",
    title: "Welcome to GPs on Vermont Dr Angela Ramsay",
    contents: [
      "We are very pleased to announce that our team of doctors is growing.",
      "Dr Angela Ramsay will be joining us as our new Locum Doctor, Dr Ramsay has a Bachelor of Medicine and Bachelor of Surgery degrees MBChB (Bristol), Diploma of Obstetric, Diploma Anaesthetic.", 
      "She has been a General Practitioner in the Albury Wodonga area since 1988, and has a special interest in Women Health, Family Planning and Antenatal Care.",
      "In behalf of GPs on Vermont, we would like to welcome Dr Ramsay as a new Locum Doctor. All of us here are excited to get to know you and look forward to working with you!",
      "Welcome Dr Ramsay to our team."
    ],
    date: "2020-06-07",
    enabled: true
  },
  {
    id: "15",
    type: "img",
    img: "prevent-hpv-cancer-news.jpg",
    pageLink: "prevent-hpv-cancer-news",
    title: "If you could prevent cancer would you?",
    contents: [
      "Help Australia eradicate HPV. Make sure your children have had all of their HPV vaccine shots!",
      "HPV causes 99 percent of all cervical cancer cases.", 
      "HPV is thought to cause 70% of oropharyngeal cancers. A cancer which disproportionately affects men.",
      "HPV can also precede oral, anal, or penile cancer.",
      "It can take years after being infected with HPV for a cancer to show up. Therefore immunisation is the most effective method to prevent HPV and its related cancers.",
      "Regardless of whether a teen or young adult engages in risky behaviour, every child has an 80 percent chance of getting HPV in their lifetime.",
      "Boys and girls around age 11 or 12 should receive their first dose of the HPV vaccine. Only one booster is needed, six months after the initial vaccine.",
      "If you wait to vaccinate until age 15 or 20, you will require two boosters for full protection. You will need to pay for one of these doses because only 2 free doses are covered under the NIP.",
      "Come in and see us if your child needs a dose.",
      "The vaccine is most effective if you make sure you have completed all the recommended vaccine doses.",
      "You can find out more about the governments HPV program and why Australia has one of the lowest cervical cancer mortality rates in the world, here..",
      "https://www.health.gov.au/health-topics/immunisation/immunisation-services/human-papillomavirus-hpv-immunisation-service",
      "For a man’s HPV caused Oropharyngeal cancer perspective read Jay Middleton’s story here..",
      "https://www.thedenverchannel.com/lifestyle/health/englewood-bicyclist-with-hpv-linked-cancer-using-bicycling-to-encourage-others-to-be-vaccinated"
    ],
    date: "2020-07-05",
    enabled: true
  },
  {
    id: "16",
    type: "img",
    img: "heart-attack-symptoms-news.jpg",
    pageLink: "recognising-heart-attack-symptoms-news",
    title: "Recognising heart attack symptoms could save yours or another’s life",
    contents: [
      "A heart attack strikes someone about every 43 seconds. How many symptoms do you know?",
      "The most well known heart attack symptoms are:", 
      " 1. Uncomfortable pressure, squeezing, fullness or pain in the center of your chest, that some describe feeling like an elephant is sitting on it. It lasts more than a few minutes, or goes away and comes back.",
      " 2. Pain or discomfort in one or both arms, the neck, jaw, stomach or back.",
      " 3. Shortness of breath with or without chest discomfort.",
      " 4. Other signs such as breaking out in a cold sweat, nausea or lightheadedness.",
      " 5. Women can experience a heart attack without chest pressure, Instead they may experience shortness of breath, pressure or pain in the lower chest or upper abdomen, dizziness, lightheadedness or fainting, nausea/vomiting, upper back pressure, jaw pain or extreme fatigue.",
      "-",
      "If you think you or someone else may be having a heart attack, call Triple Zero (000) immediately!",
      "Schedule an appointment with us today for a health check and to see what your personal risk for heart disease is."
    ],
    date: "2020-07-07",
    enabled: true
  },
  {
    id: "17",
    type: "img",
    img: "cancer-and-covid-19-news.jpg",
    pageLink: "cancer-and-covid-19-news",
    title: "Cancer and COVID-19",
    contents: [
      "Living with cancer is not easy at anytime let alone during a pandemic. Here are some of good preventative measures the cancer council recommends:",
      "Maintain good hygiene especially keep up the 20 second regular hand washing habit.",
      "Maintain social distancing and wear a face mask if you’re out in public.", 
      "Talk to us about getting the influenza vaccination if you haven’t already had it. Make sure you have a one month supply of your medications, stay home as much as possible, avoid non-essential travel and avoid public transport if you can.",
      "If possible use Telehealth to have a remote consultation with us rather than come to the clinic.",
      "Clean and disinfect frequently touched surfaces such as kitchen benches, tables, light switches, doorknobs, sinks, toilets, remotes, such as your mobile phone. Clean them with soap and water, a 70% alcohol solution or a mix of 4 teaspoons of bleach per litre of water.",
      "If you’re feeling anxious, have questions or need support, please make an appointment with our therapist Jen Walker.",
      "If you are currently experiencing symptoms of infection (e.g. fever, cough, sore throat, shortness of breath) or are aware you have been in contact with someone who has tested positive for coronavirus please contact us via the phone to discuss your options",
      "-",
      "For additional recommendations please look visit the cancer council website at: https://www.cancer.org.au/cancer-and-covid-19"
    ],
    date: "2020-07-07",
    enabled: true
  },
  {
    id: "18",
    type: "img",
    img: "nsw-vic-border-closure-news.jpg",
    pageLink: "nsw-vic-border-closure-news",
    title: "NSW - VIC : Border Closure, but it is open to patients who have scheduled appointments with us!",
    contents: [
      "From 8 July, you’ll need a border entry permit to travel from Victoria to NSW.",
      "The travel exemption permit system is up and running, you can apply online for one of those, under the medical or hospital care exemption category.",
      "You need to show your authorised appointment letter, drivers licence and any other supporting material.",
      "We are still doing Telehealth appointments if you would prefer that to coming into the clinic or if you are part of a vulnerable patient group.",
      "If possible use Telehealth to have a remote consultation with us rather than come to the clinic.",
      "Contact us for additional info or look at the gov site link here:",
      "https://www.service.nsw.gov.au/transaction/apply-covid-19-nsw-border-entry-permit",
      "Apply here: ",
      "https://disasterassistance.service.nsw.gov.au/bcpermit"
    ],
    date: "2020-07-08",
    enabled: true
  },
  {
    id: "19",
    type: "img",
    img: "gps-on-vermont-medical-centre-news.jpg",
    pageLink: "border-closure-gpv-clinic-updates-news",
    title: "Clinic update - Border Closure Arrangements",
    contents: [
      "GPs on Vermont Medical Centre want to reassure patients that we are doing everything we can to ensure you can still get the treatment you require following the upcoming closure of the NSW/VIC state border.",
      "Our hygiene protocol has remained extremely vigilant throughout this process and we continue to provide a clean and safe environment for your treatment. As such we ask all patients to adhere to the following requirements:",
      "1. Advise the receptionist if you have a sore throat, runny nose, flu like symptoms, shortness of breath, been to Melbourne or been in contact with people from Melbourne in the past 14 days. If you have one symptom, we strongly recommend you attend a Covid 19 clinic and be tested.",
      "2. On arrival for your appointment you will be asked to call reception and they will advise when it is appropriate for you to come into the waiting room.",
      "3. Your temperature will be taken before entering the clinic. If your temperature is 38 or above, you will be ask to wait in the car and have a telephone appointment.",
      "4. Please attend your appointment on your own were possible. If you require a support person please advise the receptionist.",
      "-",
      "Medical appointments are classed as essential health service and, as such, you will be free to cross the border to attend your appointment with a permit. Advise our reception staff if you need assistance with this permit application.",
      "https://www.service.nsw.gov.au/transaction/apply-covid-19-nsw-border-entry-permit",
      "We look forward to supporting you and your families’ health during this time either by a telephone or face to face appointment. If you have any questions, please don’t hesitate to call our helpful reception staff on 02 60252189.",
      "-",
      "Doctors and staff",
      "GPs on Vermont Medical centre"
    ],
    date: "2020-07-08",
    enabled: true
  },
  {
    id: "20",
    type: "video",
    img: "https://www.youtube.com/embed/eVJbenwzR1s",
    pageLink: "dos-and-donts-wearing-masks-globes-news",
    title: "Tips and tricks for wearing masks and gloves safely",
    contents: [
      "We are here today to do a fun experiment where we show how easy it is to spread germs. We are going to demonstrate this using a powder that glows underneath the blacklight.",
      "As you can see the only item in our experiment that has been contaminated with powder is the water bottle.",
      "In this experiment the powder represents our bacteria our germs and viruses so we can demonstrate how easy it is to spread this even if we're running into the grocery store with proper protective equipment (Mask & Gloves).",
      "",
      "",
      "",
      "",
      "",
      ""
    ],
    date: "2020-07-14",
    enabled: true
  },
  {
    id: "21",
    type: "img",
    img: "cancer-symptoms-trials-news.jpg",
    pageLink: "cancer-symptomps-trials-news",
    title: "Cancer Symptoms Trials (CST)",
    contents: [
      "A team of investigators from the Cancer Symptoms Trials (CST) unit at The University of Technology Sydney (UTS) are currently conducting a survey regarding: Defining Research Priorities and needs in Cancer Symptoms for adults who are living with or have had cancer.",
      "They are hoping to address the unmet symptom management needs of Australians living with cancer.",
      "If you want more information, and or to begin the survey, please click on this link: www.uts.edu.au/cst/cstprojects.",
      "If you want to know about clinical trials available specific to your type of cancer please ask us about it at your next appointment."
    ],
    date: "2020-08-03",
    enabled: false
  },
  {
    id: "22",
    type: "img",
    img: "blue-september-prostate-cancer-news.jpg",
    pageLink: "blue-september-aka-prostate-cancer-awareness-month-news",
    title: "Blue September aka Prostate Cancer(PCa) Awareness Month",
    contents: [
      "This month we are highlighting Prostate Cancer (PCa) awareness and this is a good time to reflect on and discuss Australian men’s health.",
      "Prostate Cancer is actually the most common cancer in Australian men!",
      "It kills approximately 3500 Australian men each year!.",
      "And many more are having their quality of life affected by its symptoms and complications.",
      "It occurs when abnormal cells in the prostate grow in an uncontrolled way.",
      "It is most treatable when detected early before it has had a chance to spread to other parts of the body.",
      "Please be aware that in the early stages, there may be no symptoms that’s why we strongly encourage regular checkups.",
      "Symptoms usually show up in later stages and often start to occur as the prostate enlarges and presses on the urethra.",
      "Prostate Cancer symptoms include:",
      "1. Feeling the frequent or sudden need to urinate",
      "2. Finding it difficult to urinate (for example, trouble starting or not being able to urinate when the feeling is there or having poor urine flow)",
      "3. Discomfort when urinating",
      "4. Finding blood in urine or semen",
      "5. Pain in the lower back, upper thighs or hips.",
      "6. Sexual dysfunction",
      "If you or the men in your life have experience any of these symptoms or have noticed unusual changes make an appointment with us as the earlier it’s detected the better the outcome.",
      "If you would like more information please visit:  https://www.canceraustralia.gov.au/affected-cancer/cancer-types/prostate-cancer/ or https://www.prostate.org.au/"
    ],
    date: "2020-09-03",
    enabled: true
  },
  {
    id: "23",
    type: "img",
    img: "how-are-you-managing-your-mental-health-news.jpg",
    pageLink: "how-are-you-managing-your-mental-health-news",
    title: "How are you managing your mental health? Are you looking after yourself",
    contents: [
      "If you are struggling to cope, sleep, feeling anxious, depressed or very stressed please make an appointment to visit us for a chat we take mental health seriously.",
      "You don’t have to go through this time of uncertainty alone.",
      "Remember that we have Jen Walker, our wonderful therapist, here and available to help.",
      "We acknowledge that the Covid19 pandemic has added to the already existing anxiety around climate change, the economy and the future and decreased people’s optimism.",
      "Some strategies people have found to help include:",
      "1. Connecting with friends and loved ones regardless if it’s online over the phone etc.",
      "2. Finding a support group.",
      "3. Joining a interest group, there are now lots of groups online", 
      "4. Laughter is a great way to get more feel good hormones into your system, whether it be with a standup comedy, sitcom, funny animal clips, a movie or an online laughing yoga group or sharing jokes with a friend. Whatever the form we encourage lots of it!. Play some music you can always dance as if no one is watching.",
      "5. Sunshine and vitamin D",
      "6. Exercise helps with a sense of self care, routine and purpose and those happy hormones again.",
      "7. A varied and balanced diet helps fuel our body and its hormones. Are you eating a rainbow of foods?.",
      "8. Treat yourself to some me time and do something special just for you.",
      "9. And giving yourself permission and know that it’s ok to experience and acknowledge the emotions that arise during these uncertain times.",
      "10.Giving back to the community gives a sense of connection, satisfaction, purpose and empowerment.",
      "",
      "For more ideas and information on how to manage your mental health please visit: https://www.health.gov.au/news/health-alerts/novel-coronavirus-2019-ncov-health-alert/ongoing-support-during-coronavirus-covid-19/looking-after-your-mental-health-during-coronavirus-covid-19-restrictions",
      "Please don’t hesitate to reach out to us for help.",
      "If you need help right now, acute and crisis mental health care is available 24 hours a day, 7 days a week, anywhere in Australia please call",   
      "*  Beyond Blue.                            Phone: 1800 512 348",
      "*  Kids Helpline.                          Phone: 1800 551 800",
      "*  Lifeline 24-hour crisis line.           Phone: 131 114",
      "*  Health Direct (Aboriginal and Torres Strait)    Phone: 1800 011 511  ... and ask for the local crisis team",
      "*  National Relay Service (Hearing or speech impairment).    Phone: 1800 555 677 ... and ask to be transferred to healthdirect ... and ask for the local crisis team",
      "*  Suicide Call Back Service.            Phone: 1300 659 467",
      "*  Translating and Interpreting Service (TIS National).    Phone:  131 450",
      "If it is an emergency please dial 000"
    ],
    date: "2020-09-05",
    enabled: true
  },
  {
    id: "24",
    type: "img",
    img: "prostate-check-up-news.jpg",
    pageLink: "what-is-the-prostate-where-is-located-news",
    title: "What is the Prostate? Where is it located? And how is Prostate health checked?",
    contents: [
      "The Prostate along with the testicles and seminal vesicles comprise the male sex glands",
      "These glands are instructed by the sex hormones like testosterone to secrete the fluids which make up semen.",
      "A normal prostate is about the size of a walnut and lies just below the bladder and surrounds the beginning of the urethra (the tube that allows urine and semen to be released).",
      "The state of your prostates health can be indicated by a Prostate Specific Antigen (PSA) blood test.",
      "We recommend our male patients include a PSA blood test as part of their regular health check up routine.",
      "A high PSA test result can indicate if we need to refer you to a specialist for further investigations into prostate diseases and or cancer.",
      "You can find out more about the prostate here: https://www.prostate.org.au/"
    ],
    date: "2020-09-17",
    enabled: true
  },
  {
    id: "25",
    type: "img",
    img: "alzheimers-dementia-news.jpg",
    pageLink: "how-much-do-you-understand-about-alzheimers-and-dementia-news",
    title: "How much do you understand about Alzheimers & Dementia?",
    contents: [
      "Are you aware it’s the third leading cause of death among all Australians, behind heart disease and Cerebrovascular diseases?",
      "As our population ages Dementia diagnoses will continue to increase.",
      "Dementia is actually a term used for a number of different progressive degenerative brain syndromes.",
      "These syndromes affect memory, thinking, behaviour and emotion.",
      "Alzheimers and Vascular Dementia are the most common types of dementia.",
      "Every person with dementia will experience it in their own way but will eventually be unable to care for themselves and will need help with daily life activities.",
      "Though we cannot change getting older, genetics or our family histories.",
      "Some things you can do to lower your individual risks are:",
      "",
      "1)  Have a regular heart health check-up eg. blood pressure and cholesterol",
      "2)  Stop smoking",
      "3)  Follow Australian dietary guidelines for a healthy diet",
      "4)  Engage in regular exercise",
      "5)  Practice good sleep habits",
      "6)  Look after your hearing and get your hearing checked",
      "7)  Protect your head",
      "8)  Challenge your brain with mental exercises",
      "9)  Look after your psychological wellbeing",
      "10) Stay social and connected with others",
      "",
      "If you or someone you know is concerned about memory problems or how to manage with Dementia during the COVID-19 pandemic then make an appointment with us to discuss your treatment, advice, and support options.",
      "You can find out more on how to manage with Dementia during the COVID-19 pandemic here: https://www.dementia.org.au/an-update-from-dementia-australia ",
      "For additional advice and information contact the free National Dementia Helpline, it provides information and advice to people living with dementia as well as to family, friends and carers of people living with dementia. Free call: 1800 100 500 they operate Monday to Friday, 8:00am - 8:00pm (except public holidays).",
      "If you require the assistance of an interpreter, please call the Translating and Interpreting Service on 131 450.",
      "If you are deaf or have a hearing or speech impairment, please call the National Relay Service on 133 677."
    ],
    date: "2020-11-05",
    enabled: true
  },
  {
    id: "26",
    type: "img",
    img: "breast-cancer-news.jpg",
    pageLink: "breast-cancer-what-are-the-symptoms-and-signs-news",
    title: "How much breast cancer awareness do you have? Did you know it can affect males as well?",
    contents: [
      "Breast Cancer is the most commonly diagnosed cancer in Australia.",
      "Approximately 55 Australians are diagnosed each and every day.",
      "Breast Cancer incidence is on the increase.",
      "It is estimated that breast cancer has killed approximately 2997 Australian women and 33 Australian men in 2020!.",
      "Similar to other cancers it is most treatable when detected early.",
      "With early detection and treatment there is a five-year survival rate of 91%.",
      "A screening mammogram is used as a early detection tool to check patients who have no signs or symptoms.",
      "BreastScreen Australia offers a free screening program for women over 40 years of age who are at risk of breast cancer.",
      "Unfortunately, women under 40 years of age are usually not offered breast screening because their breast tissue density makes it harder to detect cancers on mammograms. However, if you have any concerns please make an appointment with us.",
      "It should be noted that BreastScreen currently has reduced screening capacity as part of their COVID-19 pandemic safety measures, so screening is happening just at slower rates.",
      "If you have new breast symptoms make an appointment for a breast health check, please don’t put it off for later.",
      "At your appointment we can examine your signs and symptoms, screen for risk factors and discuss how to reduce your risk. And in order to get a clearer picture we may refer you privately for a diagnostic mammogram, or to a public hospital imaging department that provides free diagnostic imaging.",
      "Signs and symptoms can show up any time including during the last weeks of pregnancy.",
      "Some breast cancer signs and symptoms to pay attention to are:",
      "",
      "-  A lump, such as a painless lump close to the nipple.",
      "-  Pain in the breast or chest region.",
      "-  Swollen lymph nodes (glands) under the arm.",
      "-  Nipple discharge.",
      "-  A change in the skin colour, texture and appearance of the breast, such as thickening, swelling or dimpling of the skin.",
      "-  A change in the shape and appearance of the nipple or pectorals (muscles at the front of the chest).",
      "-  If you experience any of these symptoms or have noticed other unusual changes in your breast or chest or just have concerns come in and see us to discuss it.",
      "",
      "If you would like more information about your risk you can also try the breast cancer risk assessment tool iPrevent here: https://www.petermac.org/iprevent.",
      "To book a screening please call BreastScreen Australia on 13 20 50 or visit their website: http://www.cancerscreening.gov.au/internet/screening/publishing.nsf/Content/breast-screening-1.",
      "For additional information you can visit Breast Cancer Network Australia at https://www.bcna.org.au or call their information line on 1800 500 258.",
      "For help and advice regarding coronavirus (COVID-19) and cancer, read advice and FAQs from The Cancer Foundation here: https://www.cancer.org.au/support-and-services/cancer-and-covid-19.",
      "For more information on breast cancer for Aboriginal and Torres Strait islander women. Please visit here: https://nbcf.org.au/about-breast-cancer/further-information-on-breast-cancer/breast-cancer-in-aboriginal-and-torres-strait-islander-peoples/",
      "Please note that fact sheets are available on breast cancer and screening in several languages besides English from The NSW Multicultural Health Communication Service here: https://www.mhcs.health.nsw.gov.au/@@search?SearchableText=breast+cancer.",
      "",
      ""
    ],
    date: "2020-11-10",
    enabled: true
  },
  {
    id: "27",
    type: "img",
    img: "gps-on-vermont-medical-centre-news.jpg",
    pageLink: "season-greetings-and-best-whishes-for-ny-news",
    title: "Seasons Greetings and Best Wishes for the New Year!",
    contents: [
      "Wow! What a year! It has been a privilege to be part of such an amazing and caring community.",
      "",
      "Thank you for your support, adaptability and patience with the challenges and disruptions we have faced this year.",
      "Thank you for bearing with us during the occasions when we haven’t gotten things right and for allowing us the opportunity to improve.",
      "While 2020 has been a challenging year it has emphasised what really matters.",
      "Kindness, appreciation and connection with our communities, the planet and love ones.",
      "So thank you for welcoming us into the wonderful Aulbury Wodonga communities and for including us in your healthcare journey.",
      "We wanted to take an opportunity to wish you all the best for this holiday season and the New Year!",
      "Stay well, stay safe and keep practicing the basics!",
      "If there is an emergency during this time please dial 000 or go to the emergency department across the road.",
      "We are looking forward to continuing to being part of your healthcare journey in 2021.",
      "",
      "Best wishes, the team at GP’s on Vermont.",
      ""
    ],
    date: "2020-12-23",
    enabled: true
  },
  {
    id: "28",
    type: "img",
    img: "gps-on-vermont-medical-centre-news.jpg",
    pageLink: "welcome-dr-barkas-news",
    title: "Welcome, Dr. Barkas!",
    contents: [
      "We are excited to announce that Dr. Richard Barkas has joined our team on a part-time basis!",
      "Dr. Barkas is very experienced with many years in general practice and holds qualifications in Medicine, Surgery, Obstetrics and Gynaecology.",
      "He is very interested in family health, men’s health and has a special interest in skin checks.",
      "Dr. Barkas is looking forward to meeting our patients, their families and becoming part of the GP’s On Vermont community.",
      "He will begin working with us from the 20th January 2021",
      "Welcome, Dr. Barkas it's great to have you onboard!",
      "."
    ],
    date: "2021-01-19",
    enabled: true
  },
  {
    id: "29",
    type: "img",
    img: "heat-stress-news.jpg",
    pageLink: "heat-stress-and-some-heatwave-preparation-tips-news",
    title: "Feeling hot hot hot! What are the signs of heat stress and are you being heatwave safe?",
    contents: [
      "As the mercury level rises it’s a good reminder to pay attention to your body for signs of heat stress.",
      "Some of the key signs to look for include:",
      "- Heat Rash",
      "- Muscle cramps",
      "- Pallor",
      "- Exhaustion",
      "- Dizziness",
      "- Fainting",
      "- Headache",
      "- Nausea",
      "- Increased heart rate",
      "- Excessive sweating",
      "- Or having hot, dry skin, a temperature and No sweating!",
      ".",
      "Be extra careful if you or a loved one have any of the following conditions as you’re more at risk of heat-related morbidity and mortality:",
      "- Cardiac events",
      "- Asthma or other respiratory illness",
      "- Kidney disease",
      "- Diabetes",
      "- Nervous system diseases",
      "- Cancer",
      ".",
      "Also be careful of excessive exertions during this period as you could become quickly dehydrated and overtax your body’s systems!",
      "Even mild dehydration can result in injury, illness and lower levels of performance.",
      "If you have any concerns please visit or contact us to discuss them.",
      "Some useful tips when preparing for heatwaves include:",
      "1. Try to get your body temperature down by getting cool. A freshly wet towel especially on the back of the neck can help.",
      "2. Make sure you’re getting enough fluids especially enough water! It’s a good idea to reduce consumption of substances that could make you more dehydrated such as alcohol, tea and sugary drinks. Also when out and about have a bottle of water on you.",
      "3. Cool down your home. Draw your blinds or curtains to block out the sun. Use a well-functioning fan or air conditioner and if possible put up external awnings.",
      "4. When out in public seek out cooler places and shady spots. Be sun safe wear a wide brimmed hat, sunglasses, protective clothes and sunscreen. Also remember to have hand sanitizer, a mask handy and to socially distance staying 1.5m from other groups as the highly contagious Covid19 virus is still around!",
      "5. Check on others especially those that are more vulnerable such as children, elderly or those with chronic health conditions. Again due to the pandemic we would advice if possible doing this via a phone or video call rather than visiting in person. If you aren’t able to do so but are aware of someone who is vulnerable and would benefit from a regular check-in then services like the The Red Cross’s Telecross could be a option. (https://www.redcross.org.au/get-help/community-services/telecross).",
      "6. Keep your food safe. Don’t leave it out in the heat to spoil.",
      "More detailed information on these tips and other helpful advice can be found at: https://www.health.nsw.gov.au/environment/beattheheat/Pages/prepare-for-heat.aspx",
      "If you want to find out when and which areas are predicted to have heatwaves please visit: http://www.bom.gov.au/australia/heatwave/",
      "If you have a medical problem that is being exacerbated by the heat or if you suspect that you are suffering the effects of heat stress or need some other health support please contact us for an appointment as soon as possible.",
      "If you or a loved one are experiencing severe symptoms and require urgent medical assistance dial 000!."
    ],
    date: "2021-01-26",
    enabled: true
  },
  {
    id: "30",
    type: "img",
    img: "poison-medication-heatwave-news.jpg",
    pageLink: "medication-safety-reminders-during-heatwaves-news",
    title: "Some Medication Safety Reminders Especially for During Heatwaves!.",
    contents: [
      "Please pay attention to the medications you’re taking as some prescribed medications can increase the risk of heat-related illness.",
      "Some medications may cause fluid loss or interact with how your body regulates itself. This can result in more risk of dehydration or increased heatwave impact. If you’re not sure about your medications risks please contact us.",
      "Additionally if medication is exposed or stored at an incorrect temperature it can become less effective or even toxic.",
      "Most medications need to be stored below 25 C such as Insulin, Antibiotics and Sedatives.",
      "Store your medicine according to their instructions paying special attention to this during hot weather.",
      "Keep them in the fridge if their instructions advices to do so!",
      "If you have any unused, unwanted, damaged, heat affected or expired medications please do not flush them down the toilet or put them in the rubbish bin!",
      "Instead take them to your nearest pharmacy where they can dispose of them safely and freely for you!",
      "Incorrect non secured disposal can lead to unintentional harm, especially to our local water systems, natural environment, members of our households and community.",
      "To avoid poisoning and prevent accidental or intentional ingestion of medications please follow safe and proper medicine disposal practices.",
      "Remember that medications used for the correct purpose and in the correct dose and state can improve and even save lives but for unintended recipients that same medication can be a poison and even fatal.",
      "We wish you a happy, safe and healthy summer!",
      "For more information on responsible medication disposal you can look here: https://www.tga.gov.au/safe-disposal-unwanted-medicines.",
      "If you need to refill your prescriptions or have a query about them or any of the information mentioned above please make an appointment to come in and see us.",
      "."
    ],
    date: "2021-01-31",
    enabled: true
  },
  {
    id: "31",
    type: "img",
    img: "sleep-deprivation-news.jpg",
    pageLink: "are-you-getting-the-right-amount-of-sleep-news",
    title: "Are you getting the right amount of sleep? Sleep deprivation isn’t just frustrating it can also have a serious toll on your health and overall quality of life!.",
    contents: [
      "The Australian government is taking this issue so seriously that it was the first country in the world to order a federal inquiry into Sleep Health Awareness.",
      "The federal inquiries report “Bedtime Reading” states that four in every ten Australians are regularly experiencing inadequate sleep.",
      "The report also states that school aged children should get between 9 and 11 hours each night and for adolescents to get 8 and 10 hours each night.",
      "The National Sleep Foundation in the United States of America recommends that adults should aim for 7 - 9 hours of sleep a night.",
      "The healthy time range for falling to sleep is 10 - 20 mins. If it takes you an hour or more to get to sleep it could be an indicator of a more significant problem such as having a sleep disorder.",
      "Not getting the required amount of sleep can contribute to poorer health outcomes and mental states such as:",
      "1 ) Heart disease",
      "2 ) Diabetes",
      "3 ) Worsened immune function",
      "4 ) Disruption to hormone production and regulation",
      "5 ) Depression, anxiety and other impacts on mental health",
      "6 ) Make pain conditions worse",
      "7 ) Increased risk of accidents",
      "8 ) Less productivity",
      "9 ) Lack of energy",
      "10) Poor mental recall",
      "11) Foggy thinking and reduced attention span",
      "12) Sleeping while driving.",
      ".",
      "So please come in to have a chat with us about it.",
      "The sooner the cause is identified the quicker we can start working on solutions and have you well rested in no time.",
      "You can find more information on the benefits of sleep for health and performance as well as other fantastic resources at https://www.sleephealthfoundation.org.au.",
      "You can also download their ten tips for a Good Night’s Sleep here: https://www.sleephealthfoundation.org.au/pdfs/facts/Tips%20for%20a%20Good%20Night's%20Sleep.pdf",
      "The government website Head to Health also has some good sleep apps, online courses and peer forums click here for more info: https://headtohealth.gov.au/search-resources.",
      "The federal inquiry Bedtime Reading report can be found here: https://www.aph.gov.au/Parliamentary_Business/Committees/House/Health_Aged_Care_and_Sport/SleepHealthAwareness/Report.",
      ""
    ],
    date: "2021-02-11",
    enabled: true
  },
  {
    id: "32",
    type: "img",
    img: "happy-Anniversary-news.jpg",
    pageLink: "gps-on-vermont-first-anniversary-news",
    title: "It’s Our 1st Birthday",
    contents: [
      "Yay! Today marks our first anniversary and what a year it has been!. It’s hard to believe that this time last year we were opening our doors for the first time!",
      "We are so grateful to you all for choosing us to be a part of your health and wellness team. Thank you as well for supporting us with your kind welcome into the Albury Wodonga community.",
      "-",
      "With sincere thanks and best wishes from everyone here at GP’s On Vermont!"
    ],
    date: "2021-03-17",
    enabled: true
  },
  {
    id: "33",
    type: "img",
    img: "sleep-apnoea-news.jpg",
    pageLink: "is-your-or-your-partners-snoring-keeping-you-awake-sleep-apnoea-news",
    title: "Is your or your partners snoring keeping you awake? They could have Sleep Apnoea?",
    contents: [
      "So what actually is snoring?",
      "Snoring occurs when airway passages are narrowed or obstructed causing inhaled air pressure to vibrate the soft palate.",
      "The airways or air passages run from your nose to your chest.",
      "In order to treat the snoring the cause needs to be identified.",
      "Possible causes could be:",
      "A blocked nose due to a cold or an allergy. This would be a temporary case of snoring, worsened if you need to open your mouth to breath as this would accentuate the vibration of the soft paplate.",
      "Extra weight and or soft tissue around your neck (can be caused by gaining weight or aging).", 
      "A very small jaw structure.",
      "The various causes mentioned above can all cause a drop in blood oxygen levels, due to the obstructed airways. The drop in blood oxygen levels results in needing to wake up in order to be able to breathe again. This results in a disrupted sleep of frequent waking through the night. The pattern repeats each time you fall back to sleep. Which also causes surges in blood pressure and in turn can put stress on the heart. All of this can result in not getting enough healthy deep sleep, disrupted hormone cycles, brain fog, poorer mental health and feeling fatigued constantly during the day etc.",
      "Luckily normal snoring is not considered to raise your risk of cardiovascular disease, stroke or high blood pressure.",
      "However, in sleep apnoea’s case breathing becomes disrupted. In some types of sleep apnoea the airway can become completely blocked off and result in a cessation of breathing altogether.",
      "Where as regular snoring generally occurs without the cessation of breathing.",
      "Sleep Apnoea should be taken care of as soon as possible due to the serious health risks associated with it.",
      "Sleep Apnoea is often broken down into three types:",
      "1)  Obstructive sleep apnoea (OSA): involves a physical or anatomical blockage or narrowing of the airways.",
      "2)  Central sleep apnea: is a result of a communication issue between the brain and the airway muscles and doesn’t involve an obstructed airway.",
      "3)  Complex sleep apnea: in brief is a combination of obstructive sleep apnoea and central sleep apnea. It is fairly involved and more information about it can be found in the links listed below.",
      "Obstructive Sleep Apnoea is the most common of the three types and is further classified based on its severity.",
      "Its usually classified into mild, moderate or severe types. Severe OSA is considered the more harmful type as it repeatedly stops the breathing process during sleep. Where as in mild OSA sleep isn’t as disrupted. However, this can make it harder initially to identify as a cause of the sleeping disorders associated symptoms.",
      "Some general indicators of Sleep Apnoea can be:",
      "- Chronic snoring",
      "- Disrupted sleep often with frequent sudden waking",
      "- Constantly feeling tired on waking or fatigued throughout the day",
      "- Others noticing you pausing in breathing while asleep",
      "Some immediate remedies that could be trialed for mild OSA include:",
      "Changing your sleeping posture. For example switching to side lying instead of lying on your back.",
      "Reducing alcohol consumption. As it can impair the brains functionality including its instructions to the muscles that control breathing.",
      "If overweight losing some weight to ease any additional pressure around the neck and on the airways.",
      "If these changes don’t help you may need to see us to discuss other options such as:",
      "Using a mouth guard. These can range from store bought to actually seeing a specialist to get one especially fitted for your jaw",
      "CPAP (Continuous Positive Airways Pressure). It is a device that works like a reverse vacuum cleaner. Instead of sucking air up, it blows air into the airways to keep the airways propped open. However, it is a treatment that may not suit a lot of people as it involves wearing a mask every night when you sleep.",
      "Surgical procedures. These may be particularly considered if you’re a young person with the problem and it’s due to a structural issue like having a small jaw.",
      "As you can see snoring and sleep apnoea can be quite complex and have many causes. So the treatment options vary widely as well. There are more treatment options available than those mentioned above.",
      "You and your loved ones deserve a decent unbroken nights sleep!",
      "So please if either you or your loved one are a snorer, and you’re concerned about the impact it’s having on your health, make an appointment to see us to discuss finding the cause for your condition and its subsequent treatment options. As the sooner the condition is diagnosed the sooner treatment solutions can start and a good nights sleep can be had.",
      "More help, advice and information on Sleep Apnoea can be found at: https://www.healthdirect.gov.au/sleep-apnoea and or at: https://www.betterhealth.vic.gov.au/health/conditionsandtreatments/sleep-apnoea",
      ".",
      "For more detailed information on Sleep Apnoea’s classifications visit here: https://academic.oup.com/sleep/article/39/5/1165/2454063.",
      "-"
    ],
    date: "2021-03-28",
    enabled: true
  },
  {
    id: "34",
    type: "img",
    img: "book-your-flu-shot-news.jpg",
    pageLink: "book-your-flu-shot-2021-news",
    title: "Flu Shots Have Arrived!",
    contents: [
      "It’s that time of year again! Just as Autumn has arrived so have the first batch of Flu Vaccines.",
      "At this stage we only have vaccines for those 65 years and over.",
      "So if you are part of this fantastic group of people we have your shot ready and waiting for you.",
      "If you would like to receive your Flu shot please ring 02 6025 2189 to book an appointment.",
      "We are looking forward to seeing you soon!"
    ],
    date: "2021-03-28",
    enabled: true
  },
  {
    id: "35",
    type: "img",
    img: "vaccine-shot-1-news.jpg",
    pageLink: "flue-vaccine-conditions-update-news",
    title: "Flu vaccine update",
    contents: [
      "Please note that the flu vaccine is recommended for everyone from six months of age, but it's available free under the National Immunisation Program for people who face a high risk from influenza and its complications.",
      "These are:",
      "1.- People aged 64 years and over.",
      "2.- Aboriginal and Torres Strait people aged six months to less than five years.",
      "3.- Aboriginal and Torres Strait islander people who are aged 15 years and over.",
      "4.- Pregnant women.",
      "5.- People aged six months and over with medical conditions such as severe asthma, lung or heart disease, low immunity or diabetis that can lead to complications from influenza.",
      ".",
      "All other individuals not included in the categories above can purchase the vaccine from us for a $20 fee.",
      ".",
      "Come in and get your flu shot, it's your best defense against the flu season.",
      "If you would like to receive your flu shot please ring 02 6025 2189 to book an appointment.",
      "We are looking forward to seeing you soon!"
    ],
    date: "2021-04-14",
    enabled: true
  },
  {
    id: "36",
    type: "img",
    img: "endometriosis-concern-news.jpg",
    pageLink: "are-you-having-difficulty-conceiving-news",
    title: "Are you having difficulty conceiving? Do you experience pain with intercourse? Or have abdominal pain that seems to come and go with your menstrual periods? You could be suffering from Endometriosis!",
    contents: [
      "Endometriosis also commonly referred as Endo is a complex chronic and usually painful condition that can disrupt women’s lives.",
      "It’s a condition where endometrium tissue that should leave a women’s body each month with her menstrual blood instead grows abnormally in areas outside of the uterus. Such as on the outer surface of the uterus, on connective tissues, on or around the fallopian tubes and the ovaries or even through the uterus’s muscular wall (a condition called adenomyosis).",
      "Though this tissue is in the wrong place, it’s cells still act like they are in the uterus and so can grow and bled every month just like they do during a women’s monthly cycle.",
      "This can cause irritation and pain in the surrounding tissue as the blood has no where to escape to. Resulting in inflammation and scaring. Unfortunately, this scar tissue can sometimes block the fallopian tubes making it hard to get pregnant.",
      "What causes it?",
      "There is no known cause for the endometriosis at this stage. Though it sometimes runs in families and interestingly may temporarily improve with pregnancy and resolve after menopause.",
      "It presents differently in everyone but some common symptoms include:",
      "* Persistent or continuous pelvic and abdominal pain",
      "* Menstrual cycle pain, disturbances or dysfunction",
      "* Migraines",
      "* Pain when defecating or urinating and/or bowel and urinary disturbances",
      "* Pain with intercourse.",
      "How is it diagnosed?",
      "Currently endometriosis can only be diagnosed via a surgical biopsy (laparoscopy).",
      "Due to it’s complex nature endometriosis often mimics symptoms of other conditions and so can be misdiagnosed if assessing the symptoms alone.",
      "Often a pelvic, abdominal or vaginal examination is performed or a trans abdominal ultrasound scan may be required in order to rule out other causes and to be used as a marker. However, these examinations are not effective tools for diagnosing endometriosis.",
      "Hopefully in the not too distant future diagnostic tools won’t be limited to a surgical biopsy and a non-surgerical intervention such as a simple blood test or a scan will be able to diagnose it.",
      "How is it effectively treated and managed?",
      "Endometriosis treatment and management is currently focussed on improving the woman’s quality of life by relieving symptoms and discomfort such as pain.",
      "When choosing the treatment method a woman’s individual symptoms, health, life goals and situation will be considered. As well as any contraindications, the dose strength required and any possible side effects of the medication such as weight gain or mood disturbances. Also if she is wanting to have children in the near future then we would need to discuss and plan which pill or treatment method would have the least impact on that goal and what would work best for her.",
      "That being said there are three general approaches to treatment that are used these are:",
      "1. Pain management with the use of NSAIDs (nonsteroidal anti-inflammatory drugs).",
      "2. Hormonal management with either a combined oral contraceptive pill or an oral progestin pill.",
      "3. Removal of abnormal endometrial tissue via Laproscopic surgery.",
      "What can you do to help diagnosis or manage it?",
      "1. Track your pain in a pain diary or on a menstrual app. This is helpful in identifying any patterns. For example does the pain line up with your menstrual cycle?",
      "2. Once diagnosed if there are any problems with your treatment or if your life plans change make an appointment with us as soon as possible so we can amend your treatment accordingly. As mentioned above there are a range of treatments available. The same treatment doesn’t necessarily work the same way for everyone. It depends on your individual situation, stage of endometriosis, age and pregnancy plans as to what will work best for you.",
      "If you have any queries about the information mentioned above, are experiencing symptoms, have concerns about your fertility or any other health concerns make an appointment to see us. It could be endometriosis or something else behind your symptoms. The sooner we can help you get a diagnosis, the sooner we can help with treatment and management and you can return to having a good quality of life.",
      "If you would like to learn more about endometriosis please visit: https://www.healthdirect.gov.au/endometriosis or visit The World Endometriosis Society for more information, research and useful resources: http://endometriosis.ca/.",
    ],
    date: "2021-04-28",
    enabled: true
  },
  {
    id: "37",
    type: "img",
    img: "gps-on-vermont-medical-centre-news.jpg",
    pageLink: "corona-virus-update-2021-05-29-news",
    title: "A Coronavirus (COVID-19) Update: Yes! We are open!",
    contents: [
      "You can either have your appointment in person at GP's on Vermont Medical Centre or via Telehealth.",
      "To make an appointment please select the appointment tab in this website, or visit Hotdocs or alternatively you can call our fantastic reception staff on: 02 6025 2189.",
      "On your arrival to the practice please call reception and make sure you are wearing a mask!",
      "If in the last 14 days, you have developed symptoms such as a fever, cough, sore throat, fatigue, or shortness of breath; OR you have travelled overseas or interstate: OR you believe that you have been in close contact with a suspected case of Coronavirus (COVID-19); OR if you have been recommended to self-isolate or quarantine please advise our receptionists. Our doctor will see you in your car in our carpark if you are wearing a mask. In some cases a Telehealth appointment will be recommended.",   
      "New VIC state government restrictions came into effect on Thursday 27 May at 11:59pm so please be aware of the following:",
      "1. Face masks must be worn indoors and outdoors whenever you leave your home. Unless you are working alone.",
      "2. When coming to the medical centre you’re required to call the reception first before entering the centre.",
      "3. Where possible please aim to stay 1.5 metres apart.",
      "4. Continue to practice good hygiene and good coughing and sneezing etiquette.",
      "Remember: Wash your hands often. Use soap for 20 seconds. Then dry. This kills the virus by bursting its protective bubble.",
      "Hand sanitizer is available at the medical centre in addition to washing your hands prior to leaving your home.",
      "5. Let our reception staff know if you have had any of the following signs or symptoms: a fever, cough, sore throat, fatigue, or shortness of breath, or you have a rash.",
      "6. Please get tested even if you have the mildest of symptoms.",
      "You can leave your home for medical appointments, in an emergency or if there is family violence.",
      "If you feel unsafe due to family violence and need support call safe steps on 1800 015 188 or email safesteps@safesteps.org.au they offer help 24 hours a day, 7 days a week.",
      "If it is an emergency situation dial 000.",
       "If you suspect you may have coronavirus (COVID-19) you can call the dedicated hotline – open 24 hours, 7 days on: 1800675398",
       "At GP’s on Vermont we care about our patients and the larger communities health. We will continue to practice good and appropriate protection measures at the clinic such as wiping down the touch areas several times a day. As well as closely monitoring the governments latest guidelines and updates regarding the Coronavirus (COVID-19) pandemic and adapting our practice procedures accordingly.",
      "Thank you for your understanding and patience during these unpredictable times. We apologise for any inconvenience caused.",
      "Stay safe and look out for one another and the community.",
       "The latest VIC government updates on COVID-19 can be found here: https://www.coronavirus.vic.gov.au/coronavirus-covidsafe-settings.",
          
    ],
    date: "2021-05-29",
    enabled: true
  },
  {
    id: "38",
    type: "img",
    img: "gps-on-vermont-medical-centre-news.jpg",
    pageLink: "gp-position-available-2021-06-14-news",
    title: "YAY! We Are Hiring!",
    contents: [
      "We are happy to announce a full-time GP position is available for a Vocationally or Non-Vocationally Registered General Practitioner.",
      "We offer excellent rates and prestigious rooms.",
      "You are welcome to look through the premises by appointment.",
      "We are interested in someone willing to relocate to the area permanently.",
      "For expressions of interest and enquires, please contact us at: manager@gpsonvermont.com.au"
    ],
    date: "2021-06-14",
    enabled: true
  },
  {
    id: "39",
    type: "img",
    img: "bowel-cancer-news.jpg",
    pageLink: "bowel-cancer-1-news",
    title: "Australia’s has one of the highest rates of Bowel Cancer in the world and it’s our second largest cancer related killer!",
    contents: [
      "However the good news is that Bowel(Colorectal) cancer can be successfully treated with improved survival outcomes if detected early.",
      "",
      "🔆 So what is Bowel Cancer?",
      "Bowel Cancer is also often referred to as Colorectal Cancer, Colon Cancer or Cancer of the Large Intestine.",
      "These names are often interchangeable and refer to the body’s anatomical structures.", 
      "Where the tumour is located anatomically is important when considering the symptoms, deciding on treatments and for evaluating possible disease progression and prognosis.",
      "The colon (large intestine or large bowel) and rectum comprise part of the gastrointestinal tract (digestive tract).", 
      "This tract runs from your mouth to your anus and includes organs such as your stomach, small intestine, colon, rectum and anus",
      "The bowel includes the colon, which runs from the end of the small intestine to the rectum, and the rectum  which then travels on to the anus.",
      "Most bowel cancers are either found in the rectum or sigmoid colon.",
      "However they can also be found in other parts of the colon as well including the recto-sigmoid junction, splenic flexure, hepatic flexure and or caecum.",
      "Bowel cancer occurs when something triggers colon or rectum cells to mutate. This abnormal cell growth continues to grow until it forms a small lump. This lump is commonly referred as a lesion or polyp. The polyp can then continue to grow over the next five to fifteen years until large enough to grow into deeper structures and possibly metastasis (spread to other regions of the body).",
      "During a colonoscopy the surgeon will try and remove any small to medium polyps found during the investigation. With the intention of stoping the discovered polyps from growing into cancerous growths or tumours. Unfortunately, colonoscopies can’t prevent new growths from forming later on, they can only remove current ones.",
      "The good news is that the vast majority of polyps found during a screening do not turn out to be cancerous.",
      "Most bowel cancers are comprised of Adenocarcinomas (glandular epithelial cells). These cells usually produce mucous for the digestive tract.",
      "Other less common cells types which can become bowel cancer are: Squamous Cell (squamous cells that line the Bowel), Sarcomas (connective tissue cells) most in the Bowel and rectum are lyomyosarcomas (cancers of smooth muscle cells), Carcinoid or Neuroendocrine Tumour (grow in hormone producing tissues usually in the digestive system) and Lymphomas (tumours in the lyphatic system).",
      "Each type of bowel cancer has a different cause, symptoms and treatments. So if diagnosed it is useful to understand which cell type yours is comprised of.",
      "-",
      "🔆 Signs and Symptoms to look out for:",
      "▫︎▫︎⦿ Something feels wrong. Might have vague symptoms of fatigue or feeling just not right",
      "▫︎▫︎⦿ Bleeding (on toilet paper or in the stool). It may start out small and intermittently and increase in frequency and volume as time passes. Any bleeding should be investigated. It may or may not be a sign of cancer but you won’t know until you know the cause.",
      "▫︎▫︎⦿ Changes in bowel habits such as an increased frequency or change in consistency eg.diarrhoea or constipation.",
      "▫︎▫︎⦿ Fatigue or weakness. Especially unexplained prolonged fatigue.",
      "▫︎▫︎⦿ Low iron levels such as Iron deficiency Anaemia.",
      "▫︎▫︎⦿ Tenesmus (the feeling of needing to pass a stool but little or no stool is passed).",
      "▫︎▫︎⦿ Abdominal pain or cramps.",
      "▫︎▫︎⦿ Increased or prolonged periods of bloating.",
      "▫︎▫︎⦿ Significant sudden weight changes.",
      "▫︎▫︎⦿ A mass felt felt by your doctor on external abdominal palpation or in a rectal examination.",
      "▫︎▫︎⦿ It is important to note that everyone’s experience with cancer is different. Some have a lot of symptoms others have few. Often symptoms don’t appear until late in the disease progression or stage. And then they may only present as weight loss and iron deficiency anamia. So if you have a family history of bowel cancer or experience any health changes please see us to have them investigated.",
      "-",
      "🔆 Some increased risk factors can be:",
      "▫︎▫︎⦿ Age:",
      "As bowel cancers are more common in people over the age of 70. However, as Bowel Cancer Australia mentions “around 1 in 10 (10%) Australians diagnosed with bowel cancer are under the age of 50.” Learn more here: https://www.bowelcanceraustralia.org/young-onset",
      "▫︎▫︎⦿ Family history:",
      "If you have had or have a family member especially a first degree relative (a parent, brother, sister or child) whose had Bowel Cancer below the age of 45 or two or more that have had it at any age. It’s a good idea to start getting checked even if you’re asymptomatic. As you’re at a higher risk. It’s often a good idea to get tested at least 10 years earlier than the age they were when they were diagnosed with it. There could be life style factors, certain high-risk genetic disorders such as a family cancer gene that puts you more at risk.",
      "▫︎▫︎⦿ Some genetic conditions eg. FAP, Lynch Syndrome (HNPCC)",
      "▫︎▫︎⦿ Chronic inflammatory bowel disease (IBD) such as Crohn’s and ulcerative colitis",
      "▫︎▫︎⦿ Consumption of red or processed meat",
      "▫︎▫︎⦿ Cigarette smoking",
      "▫︎▫︎⦿ Under exercising, obesity and with alcohol consumption for some bowel cancers",
      "-",
      "Please note these are general risk factors only and may not apply to everyone. They’re a guide that may be used to hopefully reduce a persons chances of developing bowel cancer. They can be internal or external risks. Some bowel cancers such as neuroendocrine neoplasms (NENs) of the colon and rectum have no known risk factors other than possibly genetics. Cells frequently mutate during cell replication. Usually our immune system identifies these errors and resolves them. However, if for some reason these mutations get missed then growths occur. These growths are often harmless. But at other times as in bowel cancers they’re not. Our understanding of the human body is evolving all the time. Hopefully in the near future we will have more knowledge on the causes of different cancers. In the meantime if none of the risk factors mentioned above apply to you, but you have symptoms please make an appointment to see us.",
      "-",
      "🔆 Diagnosis",
      "Several tests will have to be performed to understand what is causing your symptoms and to rule out other conditions which may cause similar symptoms.",
      "Some examples of these other conditions are irritable bowel syndrome, chronic inflammatory bowel disease (Crohn’s and ulcerative colitis), gastritis, gastric ulcers and haemorrhoids.",
      "When Bowel Cancer is diagnosed it is then divided into numerous stages depending on how advanced it is and how deep it has spread. This helps determine the prognosis and treatment plan.",
      "If you receive a positive test result your doctor will discuss the result with you. This may include what the next steps should be and if you require further diagnostic testing in order to get a fuller picture of your situation. They will also discuss treatment options with you.",
      "As a cancer diagnosis can be a shock and overwhelming we recommend if possible having a support person with you at your appointment.",
      "-",
      "🔆 Some of the Tests and Investigations a patient might have are listed below:",
      "An external abdominal palpation or in a rectal examination.",
      "▫︎▫︎⦿ A tissue diagnosis via a biopsy.",
      "▫︎▫︎⦿ Blood Tests. These will include checks of iron levels as well as kidney and liver functioning.",
      "▫︎▫︎⦿ Full blood count.",
      "▫︎▫︎⦿ A specific indicator test looking at cancer markers.",
      "▫︎▫︎⦿ Urine tests.",
      "▫︎▫︎⦿ Endoscopy (gastroscopies and colonoscopies). These tests may be recommended by your doctor even if you have mild symptoms especially if you have a family history of bowel cancer, or if you have a previous treatment for polyps, bowel cancer or colitis. A colonoscopy could save your live. It’s one of the best tools currently for preventing and detecting bowel cancer.",
      "▫︎▫︎⦿ Radiological imaging (CT, MRI scan, Ultrasound scan, Bone scan). If diagnosed with cancer most patients will then have a staging CT scan to identify how invasive the cancer is and if there are any metastasis.",
      "▫︎▫︎⦿ Histology tests to grade any cancerous growths found.",
      "-",
      "If you have any questions or concerns about tests and or procedures you’re recommended or referred for please raise them with us. We like our patients to be informed and empowered along their health journey.",
      "-",
      "🔆 Treatment will involve working with multidisciplinary teams and possibly multiple approaches such as listed below:",
      "▫︎▫︎⦿ Surgery is often the first consideration in a treatment plan.",
      "▫︎▫︎⦿ Curative surgery",
      "▫︎▫︎⦿ Palliative surgery",
      "▫︎▫︎⦿ Medical management",
      "▫︎▫︎⦿ Chemotherapy may be used as the first step of treatment if there is metastasic disease or after surgery if there is cancer remaining",
      "▫︎▫︎⦿ Symptom control",
      "▫︎▫︎⦿ No treatment",
      "▫︎▫︎⦿ Clinical trials - you can find out more about current clinical trials at: https://www.australianclinicaltrials.gov.au/ .",
      "-",
      "🔆 We are here to help!",
      "There is a lot of emotional roller coasters on the cancer journey. Whether it’s having tests and getting their results or trying to understand and choose the best treatment options for you. It can be very daunting, confusing and anxiety inducing. We are here to support you navigate through this confusion and towards the best outcomes and quality of life.",
      "So if you are concerned about your health or have any new signs and symptoms make an appointment to see us don’t put it off.",
      "-",
      "🔆 You can find additional information about Bowel Cancer here:",
      "Bowel Cancer Australia has great resources and information you can learn more from them by visiting here: https://www.bowelcanceraustralia.org/what-is-bowel-cancer.  As well as about young-onset bowel cancer here:  https://www.bowelcanceraustralia.org/young-onset ",
      "The Cancer Council: https://www.cancer.org.au/cancer-information/types-of-cancer/bowel-cancer. Or contact their Helpline on 13 11 2",
      "-",
      "🔆 If you are aged 50-74 and are interested in applying for the free Home Test Kit (an iFOBT) you can get more information here: www.cancerscreening.gov.au/bowel. ",
      "Or you can call the Program Information Line: 1800 118 868,",
      "Test Kit Helpline: 1800 930 998",
      "Translating & Interpreting Service - 13 14 50",
      "We can also request a test for you if you have been overlooked.",
      "-",
      "🔆 If you have been diagnosed and are interested in a support group you may find the following links below a good place to start:",
      "Bowel Cancer Australia: https://www.bowelcanceraustralia.org/buddy",
      "The Peter MacCallum Cancer Centre (Peter Mac) has some great events and information resources at their Australian Cancer survivorship centre (ACSC). You can find out more about it here: https://www.petermac.org/cancersurvivorship",
      "A fantastic organisation Neuroendocrine Cancer Australia can help you connect with fellow patients that have neuroendocrine neoplasms (NENs) of the colon and rectum as well as provide great resources on this disease. More information can be found here: https://neuroendocrine.org.au/what-are-nets/",
      "Cancer voices:  http://www.cancervoices.org.au/",
      "Respecting patients choices: http://www.respectingpatientchoices.org.au/",
      "."
    ],
    date: "2021-08-05",
    enabled: true
  },
  {
    id: "40",
    type: "img",
    img: "mental-health-news.jpg",
    pageLink: "Mid-winter-is-a-great-time-to-do-a-wellness-check-and-reset-news",
    title: "How are you doing? Are you looking after yourself? Mid-winter is a great time to do a wellness check and reset!",
    contents: [
        "Wow! Only five months left until 2022. The Spring equinox on September 23 is only a couple of months away!",
        "Are you resting and recharging now so that you’ll be ready to enjoy spring and summer to the fullest?",
        "What steps can you take to improve your current wellbeing status, life and empower yourself? Are there groups or people you need to reach out to? Do you need a general health check? Or a specific investigation? When was your last skin check, bowel check, breast check, cervical smear or prostate check? ",
        "As part of your wellness check it may be useful to check-in with the following areas of your life:",
        "1. How is your body doing?",
        "▫︎⦿ Do you have any signs or symptoms that you should be paying attention to? ",
        "▫︎⦿ Any new rashes, unexplained bruises or aches and pains? ",
        "▫︎⦿ Any unusual weight or appetite changes? ",
        "▫︎⦿ How is your digestion? Bowel movements? Any abnormal bloating? ",
        "▫︎⦿ If you have a chronic conditions eg. inflammation or pain, how are you managing it? ",
        "▫︎⦿ Do you need to increase or decrease any prescriptions? ",
        "▫︎⦿ Do you need to stretch or move your body more? Or do you need to rest it more? ",
        "▫︎⦿ How is your posture? ",
        "▫︎⦿ When working at the computer are you taking micro pauses to rest your eyes and reposition yourself? Is your work station set up as ergonomically as possible, given your situation and budget, or are there areas where it can be improved? ",
        "▫︎⦿ If you’re doing tasks that require a lot of movement whether they’re job, fitness or DIY related, are you limiting injuries by using the correct tools, lifting, carrying and twisting techniques?",
        "▫︎⦿ When outdoors are you dressing appropriately for the weather and for the UV exposure? Are you wearing the appropriate footwear?",
        "▫︎⦿ How is your hearing and or vision?",
        "▫︎⦿ How is your breathing? Are you shallow breathing?",
        "▫︎⦿ Are you drinking enough water? ",
        "▫︎⦿ Are you nourishing yourself with a rainbow of foods and nutrients?  ",
        "-",
        "A useful quick reference guide The Australian Guide to Healthy Eating can be found here:",
        "https://www.eatforhealth.gov.au/sites/default/files/content/The%20Guidelines/n55_agthe_large.pdf.",
        "-",
        "The Australian Dietary Guidelines found here:  https://www.betterhealth.vic.gov.au/health/healthyliving/Healthy-eating-what-to-put-on-your-plate and here: https://www.health.gov.au/resources/publications/the-australian-dietary-guidelines are a good place to start when looking at healthy eating. However, as everyone’s body makeup, health challenges and nutritional needs are different, it is best to see a registered dietitian or nutritionist for your tailored dietary health advice.",
        "If you are noticing any changes do you need to make an appointment to see us?",
        "2. How is your mental health?",
        "▫︎⦿ Are you having trouble concentrating, suffering from headaches or migraines, experiencing dizziness, forgetfulness or brain fog? ",
        "▫︎⦿ Has the Covid19 uncertainty, in and out lockdowns, social isolation and its fear affected you? ",
        "▫︎⦿ Are you less tolerant and more reactive? ",
        "▫︎⦿ How are you managing your stress? ",
        "▫︎⦿ If you are dealing with a more serious mental health challenge we are here as well as other services to provide help. Do you need to reach out for additional help? If so contact us or some of the services listed below at the end of this article.",
        "-",
        "The following are some ideas and tips you could try to help boost your mental health:",
        "▫︎⦿ Do some low to moderate intensity exercise. This can increases oxygen levels, happy hormones and blood flow without overstressing your body. It doesn’t matter whether it’s cardio, resistance training or both. Perhaps you could try at least 2 to 3 (20 - 30 minute) sessions per week?",
        "▫︎⦿ Keep a routine. Routines help to give you a sense of control and order. Despite the chaos around you. If you have a routine is it still working for you or do you need to tweak it? If you don’t have one what do you need to include in one to then implement it? Routines don’t have to be complicated. They can be as simple as making your bed in the morning.",
        "▫︎⦿ Reduce your intake of processed foods. Unfortunately food additives, preservatives and colourings can impact your body chemistry, which in turn could affect your mood and mental health. ",
        "▫︎⦿ Get more fresh air whenever possible. Either by going outside or by opening windows. If cold indoors with the window open can another layer of clothes help? As fresh air helps remove stagnation.",
        "▫︎⦿ Increase your vitamin D levels.",
        "▫︎⦿ Include mindfulness or relaxation practices into your life. Such as meditation, breathing techniques, yoga, tai chi etc.",
        "▫︎⦿ Improve your sleep hygiene. Do you need to eliminate screens? Can you put them away at least an hour before bed time? How is your room temperature, light exposure and noise levels? Can you get a good nights sleep with them? ",
        "▫︎⦿ Take some time out from your phone, the news and or from social media for part of the day.",
        "-",
        "3. How are your emotions?",
        "▫︎⦿ Are you feeling wary, bored, feed up, lethargic, apathetic or a bit low?",
        "▫︎⦿ Going through winter and its associated blues while dealing with the covid-19 pandemic challenges is bound to make you feel a bit off kilter at the moment. ",
        "▫︎⦿ Seasonal affective disorder (SAD) is a real disorder. Its symptoms of low energy and subsequent mood fluctuations can be mildly annoying to very debilitating. ",
        "▫︎⦿ However, if you feel down for days at a time or if your mood or thoughts are hindering your quality of life including losing interest in activities you normally enjoy, please make an appointment to see us. It is normal to ask for help when you need it.",
        "-",
        "Some ideas you could try that may help boost your emotional health could be:",
        "▫︎⦿ Reaching out to friends and loved ones. Have a face to face chat. Whether via phone or in person. Though texting and emails are great. A face to face chat where you can see the other persons expressions is much more emotionally rewarding.",
        "▫︎⦿ Play some games. Take at least a weekly break to play. Whether online or in person. Consider joining a group or a club. There are plenty of games nowadays to choose from. Even if you work odd hours and live alone there are ways to connect with others especially online. ",
        "▫︎⦿ Journal or write in a gratitude diary. There are a lot of sites online that give pointers on how to do this. Some people find journaling helpful as a record for this period of time, getting strong thoughts and feelings out of the mind and body. Some find it a great tool for reflection. Others find a gratitude diary a useful tool to help refocus your attention to what has gone well in your day etc. ",
        "▫︎⦿ Increase your daily laughing quota. When was the last time you laughed? Are you laughing at least once a day? Laughter has numerous health benefits. As this webpage from the US Department of Veteran Affairs shows: https://www.va.gov/WHOLEHEALTHLIBRARY/tools/healing-benefits-humor-laughter.asp. As a society we need to increase it. Genuine laughter more often. There are some great comedy tv series online. Or perhaps you need to share some memes or social media clips with some friends. Or ring someone up for a chat and a joke. There are also laughing yoga clubs online and in the community that you may want to try. Learn more about it here: https://laughteryoga-australia.org/",
        "▫︎⦿ Doing an act of kindness for another. Can you do some volunteer work? Check in on a neighbour? It doesn’t have to be in person. It can be on the phone or via text. Or even dropping a note into their letter box when you leave the house for your daily walk. Connecting with others can be very rewarding and enhance your sense of belonging in your community.",
        "▫︎⦿ Setting boundaries and taking a time out for yourself. Do you need a break away from others anxiety and demands? Set some time aside for you. Say “No” when you need to. You cannot help others when you have no reserves left for yourself.",
        "▫︎⦿ Giving yourself permission to feel your emotions. Acknowledge them as they arise. Especially strong or uncomfortable emotions. You may find yelling these into a pillow, put them into music or writing them down in a journal could help. How ever you want to acknowledge them is unique to you. If not pleasant such as stress emotions are there things you can do to reduce them? If they temporarily feel overwhelming perhaps you need to distract yourself for a bit. Can you take a bath, burn some oils, play some music, read a book or listen to a audio book or podcast? If you’re looking for some good books or podcasts etc. our local library’s have a fabulous selection of online resources that you shouldn’t be bored. See here: https://www.wodongalibrary.com.au/ or here: https://www.alburycity.nsw.gov.au/leisure/museum-and-libraries/collections/elibrary.",
        "-",
        "4. How is your energy?",
        "▫︎⦿ Fatigued? Waking up refreshed or exhausted? ",
        "▫︎⦿ Can you manage daily tasks with ease or are you exhausted after having a long conversation or from taking a shower? ",
        "▫︎⦿ If you have a chronic health condition how are you managing your daily energy quota? ",
        "▫︎⦿ Can you identify the cause of lost energy?",
        "▫︎⦿ Are there areas or activities that are taking too much energy? ",
        "▫︎⦿ What can you do to limit any energy loss? ",
        "▫︎⦿ What can you do to increase your energy? ",
        "-",
        "If you have noticed any changes that you’re confused or concerned about, or just don’t feel quite right, or if you can’t find the cause of a new sign or symptom book in for a general health check with us. ",
        "It could be a nutritional deficiency such as low iron or vitamin D levels or it could be something a bit more serious. We won’t know unless we look for it. The earlier situations are detected the earlier they can be treated. ",
        "🔆 Take care of yourself, your loved ones and the community. Yay! Change is coming as spring isn’t too far away!,",
        "Remember we have our wonderful social worker Jen Walker here to help. You can book online or call the clinic to make an appointment with her.",
        "🔆 If you need help right now, acute and crisis mental health care is available 24 hours a day, 7 days a week, anywhere in Australia please call ....   ",
        "▫︎⦿ Head to health: https://www.headtohealth.gov.au/search-resources",
        "▫︎⦿ Beyond Blue: 1800 512 348",
        "▫︎⦿ Kids Helpline: 1800 551 800",
        "▫︎⦿ Lifeline 24-hour crisis line: 131 114  or text Lifeline’s Lateline on 0477 13 11 14 (6pm to midnight).",
        "▫︎⦿ Health Direct (Aboriginal and Torres Strait): 1800 011 511  ... and ask for the local crisis team",
        "▫︎⦿ National Relay Service (Hearing or speech impairment): 1800 555 677 ... and ask to be transferred to healthdirect ... and ask for the local crisis team",
        "▫︎⦿ Suicide Call Back Service: 1300 659 467",
        "▫︎⦿ Translating and Interpreting Service (TIS National): 131 450.",
        "-",
        "If it is an emergency please dial 000.",
        "."
    ],
    date: "2021-08-06",
    enabled: true
  },
  {
    id: "41",
    type: "img",
    img: "gps-on-vermont-medical-centre-news.jpg",
    pageLink: "welcome-dr-manthi-news",
    title: "Welcome, Dr. Manthi!",
    contents: [
      "We are excited to announce that Dr. Joyce Manthi has joined our team!",
      "Dr. Manthi is very experienced. She has worked in several countries including Kenya, Italy, the United Kingdom and now Australia. ",
      "She holds qualifications in Medicine, Surgery, Emergency Medicine and Public Health Medicine.",
      "Her special interests are in women’s health, infectious diseases, lifestyle and preventive medicine.",
      "Dr. Manthi has been working with us for several weeks now so you may have already noticed her smiling new face around the clinic. Feel free to say hello and welcome her to Australia and to our community.",
      "She is available for appointments Monday through Fridays. You book with her here: https://www.hotdoc.com.au/medical-centres/wodonga-VIC-3690/gps-on-vermont/doctors/dr-joyce-manthi",
      "Welcome Dr. Manthi we are grateful to have you onboard!",
      "."
    ],
    date: "2021-08-09",
    enabled: true
  },
  {
    id: "42",
    type: "img",
    img: "gps-on-vermont-medical-centre-news.jpg",
    pageLink: "covid-19-vaccine-update-1-news",
    title: "COVID-19 Vaccine Update",
    contents: [
      "From the 12th of January 2022, GPs on Vermont will have Pfizer Vaccine Booster shots available",
      "We will also be offering Pfizer vaccinations for children aged 5-11 years from this date.",
      "So why not start the year with some peace of mind by making sure you and your loved ones have bolstered protection against COVID-19?",
      "If you would like to receive your free vaccine shot please call our reception to book an appointment.",
      "If you need anything clarified in relation to the vaccinations please speak to your Doctor.",
      "We are looking forward to seeing you in January!"
    ],
    date: "2021-12-19",
    enabled: true
  },
  {
    id: "43",
    type: "img",
    img: "xmas-tree-news.jpg",
    pageLink: "merry-festive-season-2021-news",
    title: "Wishing You A Very Merry Festive Season And A Happy New Year!",
    contents: [
      "Wow, it’s been another interesting year! One full of challenges, changes, adaptability and resilience.",
      "Despite the challenges this past year seems to have flown by, especially in these last few months, but we couldn’t have gotten here without your support. Thank you for your patience and for bearing with us.",
      "We are looking forward to this coming year and seeing what it will bring. We are hoping and have our fingers crossed for a much smoother and peaceful year.",
      "Thank you for allowing us to be a part of your health team and to serve the wonderful Albury Wodonga community.",
      "We are looking forward to continuing to be a part of your healthcare journey in 2022.",
      "We wish you and your loved ones good health and happiness this holiday season, new year and beyond!.",
      "Stay well, stay safe and have fun!.",
      "-",
      "Best wishes and sincere thanks from the team at GPs on Vermont!."
    ],
    date: "2021-12-21",
    enabled: true
  },
  {
    id: "44",
    type: "img",
    img: "gps-on-vermont-medical-centre-news.jpg",
    pageLink: "important-notice-1-news",
    title: "Dr Carmen Padilla will be moving from Bulk Billing to Private Billing",
    contents: [
      "As from 1st July 2022, Dr Carmen Padilla will be moving to Private Billing",
      "",
      "For any additional information about fees please check the fees page at https://www.gpsonvermont.com.au/fees-page",
      "New Patients Welcome.",
      "."
    ],
    date: "2022-05-18",
    enabled: true
  },
  {
    id: "45",
    type: "img",
    img: "skin-cancer-news.jpg",
    pageLink: "how-healthy-is-your-sking-news",
    title: "How healthy is your skin? When was your last skin check? Do you know your level of risk?",
    contents: [
      "Skin Cancer is the most common human malignancy and Australia unfortunately holds the title of being the skin cancer capital of the world!",
      "The good news is that most skin cancers can be treatable if detected early and in a lot of cases they can be cured, often with just a simple surgical excision.",
      "This is why we recommend a thorough and regular skin check so we can assess your level of risk and look for any signs of skin cancer. As we always say prevention and early detection are your best protection from complications and serious disease.",
  
      "What is skin cancer?",
      "Skin Cancer arises when there is an uncontrolled growth of cells in the skin. These cells act abnormally and can invade or spread to other parts of the body. Most common skin cancers develop in the skins epidermis layer. In one of its three types of cells. Either in the squamous, basal or melanocyte cells. The squamous cells form the flat outer layer of skin. Then the round basal cells form the padding layer underneath. With the melanocytes sitting in the base layer pumping out melanin pigments. These pigments are important as they help protect against ultraviolet (UV) radiation induced DNA damage and against the transformation of skin cells into abnormal cancerous ones. For a good illustration and some more information of the skins layers and components visit here: https://www.cdc.gov/cancer/skin/basic_info/what-is-skin-cancer.htm)",
  
      "What are the most common types of skin cancers?",
      "1. Non Melanoma's (NMSC): are the most common types of skin cancers. They develop in the basal and squamous cells of the epidermis. They have a lower mortality rate than Melanoma's, are often cure-able and if low grade can be removed by general practitioners. Basal cell carcinomas (BCCs) - are the most common type of skin cancer and they make up approximately two thirds of all skin cancers. Squamous cell carcinomas (SCCs) - are the second most common type. They account for approximately 30% of skin cancers.",
      "2. Melanomas:  are the third most common, but deadliest type of skin cancer. They form in the skins melanocytes. Melanoma's cause the most skin cancer deaths as they easily spread (metastasise) into other body areas and organs. Melanoma's in situ (melanoma cells that haven't spread) account for 1.5% of skin cancers and the more dangerous invasive melanomas (melanoma cells that have invaded other areas of the body) make up about 1%.",
      "3. Rare Conditions: are the least common types of skin cancer. However, Australia leads the world in having some of the highest rates of these cancers despite them making up only approximately 1% of all skin cancers. Merkel Cell Carcinoma (MCC) in particular should be mentioned. Merkel Cell Carcinoma (MCC) also known as Neuroendocrine Carcinoma of the skin - Is one of the most aggressive types of skin cancer, with an unfortunately increasing incidence worldwide. It most commonly develops on sun-exposed skin such as on the face, neck or arms. Though it can occur anywhere on the body including sun protected areas such as inside the nose or oesophagus. It usually develops in later life, affects lighter skin types and affects men more than women. Australia has some of the highest incidences of it in the world.",
          
      "What are some known causes of skin cancer?",
      "Skin cancers like all cancers can arise due to many causes; including from genetics, age, random cell division mutations, environmental factors, radiation exposure and to other currently unknown causes. We are still learning and gaining more knowledge everyday about cancers. However with regards to skin cancer there are some known factors that greatly increase your chances of developing it.",
      "These include ageing, having low levels of melanin in your skin such as in the case of those within the lighter skin types, as well as overexposure to ultraviolet (UV) radiation whether from tanning beds, sun lamps or from the suns rays.",
      "We have a culture that loves being outdoors which is great! Especially for the fresh air, increasing our vitamin D levels, improving our mental health and for raising general fitness, however due to several factors including having a large percentage of the population within the fair skinned types, as well as our geographical positioning and having clear not heavily polluted air. This means our skies are less ultraviolet (UV) radiation protected than other regions of the world, such as those in the northern hemisphere. We have higher peak UV times with some of the highest levels of UV radiation in the world.",
      "It also doesn't help that our senses cannot feel, see or otherwise detect UV radiation, as it is the UV that causes the skin cell damage not the suns heat. That's why you can still be burnt on cloudy and overcast days. As The Bureau of Meteorology (BOM) suggests we need to get into the habit of thinking UV not heat when it comes to thinking about skin protection.",
      "The BOM have a great website and weather app. which tells you the current days UV levels as well as giving some tips on how to protect yourself. Click here to visit their site: http://www.bom.gov.au/uv/index.shtml.",
  
      "Please note: even though the risk of skin cancer is far lower in the darker skin types, some people within the darker skin types can and do develop skin cancer, so please still do self skin checks regardless of your skin type and if you notice any unusual changes or growths come in and see us.",
  
      "Some steps to protect yourself:", 
      "Whether it's winter or summertime before you go outside remember to check the UV levels such as via the free BOM or SunSmart apps. (https://www.sunsmart.com.au/resources/sunsmart-app).",
      "The BOM recommends five ways to protect yourself. These include slipping on sun-protective clothing, sloping on SPF+ sunscreen, slapping on a broad brimmed hat, seeking shade and sliding on wrap-around sunglasses to protect your retinas.",
      "Yes, retinas can be damaged from exposure to UV radiation and short blue visible light.",
      "If interested some information regarding UV retina damage can be found here: https://pubmed.ncbi.nlm.nih.gov/21617534/", 
      "The BOM also recommends putting on sunscreen about 20 minutes before you go outside and re-applying it every two hours, or more frequently if it's being rubbed off by sand, dirt, clothes, water or sweat etc.",
      "They also recommend using a SPF30 (or higher) broad spectrum, water-resistant sunscreen for maximum protection. The SPF part of the sunscreen protects from redness, the Broad spectrum part protects from long term damage. They explain the SPF factor in more detail here: http://www.bom.gov.au/uv/index.shtml.",
  
      "What to expect when having a skin check?",
      "To ensure it is as quick and easy as possible we would ask you not to wear makeup and to wear clothing and footwear that is easy to remove.",
      "A skin check is fairly quick and usually only takes about 10 minutes or so. The check will begin with being given a gown and being asked to undress, including the removal of shoes and socks.",
      "This is because your doctor needs to be able to see all of the skin that has possibly been exposed to the sun, including your scalp, ears, hands, between your fingers, the soles of your feet and between your toes. People often forget to check their soles. However, when sitting on the sand or grass with bare feet and your legs outstretched you're often exposing the soles of your feet to the sun.",
      "Your doctor will then often confirm your skin cancer history. They may decide if you have a history of previous skin cancer(s) to take a look at those areas first. They will also want to know if you have experienced blistering sun burns or used tanning beds etc. If you have any areas of concern that you want your doctor to check please point them out.",
      "Your doctor will then proceed to assess your skin from the top of your head down to your toes.",
      "If they find something that can be easily treated with liquid nitrogen it may be treated at the time of examination. Or if it is something that may need more investigation, as it could potentially be of concern, then a biopsy may be taken. The biopsy will then be sent away to a pathology lab for analysis and to confirm the diagnosis.",
      "If you feel discomfort at any stage please let your doctor know.",
  
      "Please note:",
      "We want your skin check to be as thorough and as straight forward as possible. If you have any special requirements such as disabilities or other reason that may make having a skin check a bit challenging, please let us know. Especially when you make your appointment and also on the day of your skin check so that we can better cater to your needs. Thank you!",
  
      "Biopsy Results", 
      "Regarding any biopsy's taken on the day of your skin check, we will let you know once we have received your results from the lab. If you haven't heard from us after two weeks, please contact us so we can follow up.",
  
      "If your result is abnormal, we will discuss the next steps with you.",
  
      "We generally recommend yearly checks, however as everyone is unique this can vary depending on your risk factors. For example you may be required to have more frequent monitoring such as six monthly checks.",
  
      "When doing a self skin check take a note of any new spots. Look for any that have grown or changed rapidly especially in the last three months. If you notice any changes, new growths, bleeding, unusual spots, unusual moles or changes in the shape, colour or an expansion of existing lesions or have other skin condition queries, please make an appointment to come in and see us.",
  
      "Having a skin check isn't just about looking for cancer. Your doctor will also notice the overall condition of your skin and can identify other skin conditions. Including any thinning, bruising, inflammation, rashes, swelling or ulcers. Your doctor can also identify common conditions like cold sore (herpes simplex), psoriasis, shingles (herpes zoster), ringworm, eczema and Rosacea to name a few.",
  
      "So if you want to minimise the risks of UV damage such as premature ageing and some skin cancers. Then get into the habit, especially if it's a UV Index day of 3+. or if you're going to be outside for an extended period of time (even if overcast and/or cloudy), of protecting yourself by slip, slop, slapping, seeking and sliding, in addition to having regular self skin checks and skin checks with your doctor. If these habits are maintained then hopefully our rates of skin cancer and related mortalities will drop for the following generations.",
  
      "Some additional useful and or interesting website links:",
      "If you would like additional information please visit: https://www.healthdirect.gov.au/skin-cancer-and-melanomas",
      "The cancer council offers support and has lots of useful skin cancer information and resources they be found here:  https://www.cancercouncil.com.au/skin-cancer/",
      "Some more information on what to look for when self checking for skin cancer can be found here: https://www.cancer.org.au/cancer-information/causes-and-prevention/sun-safety/check-for-signs-of-skin-cancer",
      "If you're someone who enjoys data and reports you may find this link to the governments 2016 skin cancer report useful: https://www.aihw.gov.au/reports/cancer/skin-cancer-in-australia/summary-1",
      "For additional information on Merkel Cell Carcinoma's The Neuroendocrine Organisation's has some good information: https://neuroendocrine.org.au/wp-content/uploads/2020/1",
      "An interesting 2022 article about the ambivalent effects of ultraviolet radiation can be read here: https://www.ncbi.nlm.nih.gov/pmc/articles/PMC8960955/pdf/fonc-12-866733.pdf",
      "As well.as at the websites mentioned above you can also find your UV forecast here: myUV.com.au"
    ],
    date: "2022-05-18",
    enabled: true
  },
  {
    id: "46",
    type: "img",
    img: "covid19-vaccines1-news.jpg",
    pageLink: "covid-19-vaccines-available-1-news",
    title: "Covid-19 Vaccines Available!",
    contents: [
      "Get your Covid-19 vaccines with us. We have both boosters and initial doses in stock.",
      "",
      "You can make a booking either via the online patient portal or by calling our helpful receptionists on 02 6025 2189.",
      "We are also happy to answer any queries you may have about Covid-19 vaccinations, tests, results or how to manage a positive result.",
      "We are looking forward to hearing from you soon!"
    ],
    date: "2022-09-24",
    enabled: true
  },
  {
    id: "47",
    type: "img",
    img: "q-fever-news.jpg",
    pageLink: "do-you-need-qfever-vaccine-news",
    title: "Do You Need A Q Fever Vaccine (Q-VAX®)?",
    contents: [
      "Are you aged 15 years or over and are in contact with high-risk animals or their products such as those from cattle, sheep or goats?",
      "You may be at risk of Q fever bacteria exposure!",
      "Stay protected and book in for your Q Fever vaccine screening and vaccine today!",
      "We can help determine if the vaccine is right for you. ",
      "We understand that not everyone can be vaccinated and or may not be eligible, especially if you have been in contact with the bacteria in the past. So book in for a screening and then if eligible the vaccine.",
      "You can book in to see us either via the patient portal or with our friendly reception staff on 02 6025 2189.",
      
    ],
    date: "2022-09-24",
    enabled: true
  },
  {
    id: "48",
    type: "img",
    img: "weight-loss-doctor-news.jpg",
    pageLink: "our-weight-loss-clinic-is-open-news",
    title: "Yay! Our Weight Loss Clinic Is Now Open!",
    contents: [
      "Are you ready to spring into a new you and lose your winter weight?. Do you need help to achieve your goal?",
      "We would love to help and support you to reach your goal and become a healthier you.",
      "We offer safe, supportive, evidence based and non-judgemental patient centred weight loss advice and plans.",
      "Our doctors want you to be happy and healthy.",
      "Come in and have a consultation with us to see how we can help you reach your ideal weight goals. ",
      "We can see if there are any underlying causes for your weight gain and assess if your weight gain is impacting your health. ",
      "We can help determine what your current weight goals are and what the best approaches are to reach them. We will consider underlying conditions, body composition, lifestyle, diet, sleep, hormones and much more.",
      "Depending on your unique circumstances we can provide lifestyle tools and advice on exercise plans, nutrition, dietary suppressants, bariatric surgery, psychological support and much more.",
      "Feel healthier, get more energy, feel empowered, feel better about yourself. form a good relationship with food, lose any food anxiety and enjoy eating again!",
      "Now is the time! Prioritise yourself!",
      "So what are you waiting for?",
      "Book an appointment to see us today either online or with our helpful reception staff on 02 6025 2189.",
      "We are looking forward to seeing the new healthier you! "
    ],
    date: "2022-09-24",
    enabled: true
  },
  {
    id: "49",
    type: "img",
    img: "stop-vaping-news.jpg",
    pageLink: "do-you-want-to-keep-smoking-or-vaping-news",
    title: "Do You Want To Quit Smoking Or Vaping?",
    contents: [
      "Good News Our Quit Smoking and Vaping Clinic Is Now Open! Let us help you! ",
      "We will work with you to create a personalised support plan. We can offer advice about managing cravings, discuss psychological support and prescribe suitable medications to help if required.",
      "We understand that you realise that smoking is bad for your health and the health of those around you. That it can impact not only your quality of life but also the quantity of your life. We know you know this. But do you know why you smoke? And do you know if you currently have any smoking related health conditions? You are unique and so need help and support tailored to you.",
      "We can work with you to figure out why you smoke, determine if you currently have any smoking related health conditions, help you set realistic and achievable quitting goals and help support you to become a healthier you.",
      "We are here for you. Cheering you on along your journey to success and freedom.",
      "If you would like to quit smoking before summer and the end of the year then make an appointment with us today!  ",
      "You can reach us online via the patient portal or by calling our wonderful receptionists on 02 6025 2189.",
      "We are looking forward to seeing you soon and being your quit smoking cheer squad! "
    ],
    date: "2022-09-24",
    enabled: true
  },
  {
    id: "50",
    type: "img",
    img: "gps-on-vermont-medical-centre-news.jpg",
    pageLink: "season-greatings-2022-news",
    title: "Seasons Greetings !",
    contents: [
      "As the sun sets on another year the team at GPs on Vermont would like to wish you and your family a joyously fun filled summer and an exceptionally healthy 2023!",
      "We would also like to thank you for giving us the opportunity to serve you this past year and we are looking forward to continuing to serve you in the upcoming year.",
      "Our warmest wishes for this summer season and for a very happy New Year!",
      "May you feel pleasantly relaxed and renewed!",
      ""
    ],
    date: "2022-12-23",
    enabled: true
  },
  {
    id: "51",
    type: "img",
    img: "atagi-covid-news.jpg",
    pageLink: "covid-vaccine-5th-dose-news",
    title: "It's vaccination time, bivalent booster vaccines available soon!",
    contents: [
      "Book an appointment with our helpful reception staff on 02 6025 2189",
      ".",
      "As Per Australian Technical advisory group of immunisation (ATAGI):",
      "▫︎⦿ ATAGI recommends a 2023 COVID-19 vaccine booster dose for adults in the following groups, if their last COVID-19 vaccine dose or confirmed infection (whichever is the most recent) was 6 months ago or longer, and regardless of the number of prior doses received: ",
      "▫︎▫︎⦿ All adults aged 65 years and over",
      "▫︎▫︎⦿ Adults aged 18-64 years who have medical comorbidities that increase their risk of severe COVID-19, or disability with significant or complex health needs.",
      ".",
      "▫︎⦿ ATAGI advises the following groups should consider a 2023 booster dose if their last COVID-19 vaccine dose or confirmed infection (whichever is the most recent) was 6 months ago or longer, and regardless of the number of prior doses received, based on an individual risk benefit assessment with their immunisation provider",
      "▫︎▫︎⦿ All Adults aged 18-64 years without risk factors for severe COVID-19",
      "▫︎▫︎⦿ Children and adolescents aged 5-17 years who have medical comorbidities that increase their risk of severe COVID-19, or disability with significant or complex health needs.",
      ".",
      "▫︎⦿ ATAGI advises that a booster dose is not recommended at this time for children and adolescents aged under the age of 18 who do not have any risk factors for severe COVID-19.",
      ".",
      "▫︎⦿ Regarding vaccine choice, all currently available COVID-19 vaccines are anticipated to provide benefit as a booster dose, however bivalent mRNA booster vaccines are preferred over other vaccines. These include: Pfizer Original/Omicron BA.4/5, as well as Pfizer Original/Omicron BA.1 or Moderna Original/Omicron BA.1. Moderna Original/Omicron BA.4/5 is currently under evaluation by the Therapeutic Goods Administration.",
      "▫︎⦿ COVID-19 vaccine can be co-administered with influenza and other vaccines.",
      "▫︎⦿ Administration of a 2023 COVID-19 booster dose should aim to occur prior to June 2023  and at a time of 6 months or greater following the most recent COVID-19 vaccine dose or confirmed infection.",
      "▫︎⦿ Ongoing surveillance of COVID-19 infection rates and clinical outcomes, new variants, and vaccine effectiveness will inform future recommendations for additional booster doses.",
      ".",
      "We are looking forward to hearing from you soon!"
    ],
    date: "2023-02-14",
    enabled: true
  },
  {
    id: "52",
    type: "img",
    img: "gps-on-vermont-medical-centre-news.jpg",
    pageLink: "welcome-dr-elena-douse",
    title: "Welcome Dr Elena Douse",
    contents: [
      "The team are excited to announce that Dr Elena Douse will be joining us at GPS On Vermont Medical Centre Wodonga.",
      "Dr Elena Douse has made the move from Queensland with her family and will commence at the clinic on Wednesday 24th of July.",

      ".",
      "Call us today to book your appointment",
      "02 6025 2189"
    ],
    date: "2024-07-08",
    enabled: true
  },
  {
    id: "53",
    type: "img",
    img: "medicare-news.jpg",
    pageLink: "bulk-billilng-appointments-available-2024-07-08",
    title: "Bulk Billing Appointments available!",
    contents: [
      "GPs on Vermont have increased Bulk Billing appointments available. New Patients are welcome and walk in appointments available.",
      "DVA and Students appointments with a sub specialty Practitioner.",
      "Call us at 0260 252189 or Click the Book Appointment button above",
      " •	"
    ],
    date: "2024-07-08",
    enabled: true
  },
  {
    id: "54",
    type: "img",
    img: "book-your-flu-shot-news.jpg",
    pageLink: "book-your-flu-shot-2024-07-08",
    title: "Flu season is here!",
    contents: [
      "It's that time again, Flu shots are in!. Everyone can benefit from the flu vaccine, but it’s crucial for people in certain groups.",
      "Getting the flu puts you at risk of secondary infections and serious complications, especially if you’re in a high-risk group. Possible complications include: ",
      " •	pneumonia •	bronchitis • sinus infections •	ear infections ",
      "The very young have an increased chance of developing complications from the flu. It’s important for them to stay up to date on their flu vaccinations. It’s also important to make the flu vaccine a priority if you:",

      " •	are 65 or older",
      " •	live in a nursing home or care facility",
      " •	have a chronic health condition, such as diabetes, asthma, heart disease, or HIV",
      " •	have a weakened immune system",
      " •	are a healthcare worker who may be exposed to people who are sick",
      "Also remember If you are over 65, you might need your PNEUMONIA Vaccine."
    ],
    date: "2024-07-08",
    enabled: true
  },
  {
    id: "55",
    type: "img",
    img: "appointments-available-news.jpg",
    pageLink: "book-appointment-dr-elena-douse-2024",
    title: "Book your appointment with Dr. Elena Douse",
    contents: [
      "Click the button below:",
      " •	 "
    ],
    date: "2024-08-05",
    displayBookingButton: true,
    displayBookingButtonDrId: "dr-elena-douse-1",
    disableReadMore : true,
    enabled: true
  },
  {
    id: "56",
    type: "img",
    img: "mymedicare-news.jpg",
    pageLink: "gps-on-vermont-joined-my-medicare",
    title: "We have join MyMedicare, You could join too!",
    contents: [
     
      "As you may know, the government has recently introduced a new healthcare initiative called MyMedicare. This initiative, established in response to the Strengthening Medicare Taskforce of July 2022, aims to enhance patient relationships with their healthcare teams through a voluntary patient registration system.",
      "MyMedicare offers several benefits for both patients and their practices, including:",
      "🔆 Additional government funding to support your primary care team in providing necessary care.",
      "🔆 A formalized relationship with your general practice and GP, which has been shown to improve health outcomes.",
      "🔆 Access to extended telehealth consultations.",
      "🔆 Future enhancements to patient registration benefits.",
      "As your trusted family medical practice, we are committed to notifying and registering as many of our valued patients as possible. Participating in this important government initiative will enable us to continue delivering top-quality healthcare and support you and your family in achieving your health goals.",
      "At GPs on Vermont Medical Centre we are committed to notifying and registering as many of our valued patients as possible. Participating in this important government initiative will enable us to continue delivering top-quality healthcare and support you and your family in achieving your health goals.",
      "You can register through:",
      "🔆 myGov or the Express Plus Medicare app",
      "🔆 Medicare Online Services via your myGov account.",
      "🔆 Completing a form available at the clinic. Please note that registration with MyMedicare is only possible if you have had at least two consultations with a GP at our practice. Once eligible, you can fill out the form and submit it to our friendly reception staff, who will upload it to MyGov on your behalf.",
      ".",
      "For any additional information do not hesitate to call our helpful reception staff on 02 6025 2189.",
      "We are looking forward to hearing from you soon!"
    ],
    date: "2024-09-09",
    displayBookingButton: false,
    displayBookingButtonDrId: "dr-elena-douse-1",
    disableReadMore : false,
    enabled: true
  },
  {
    id: "57",
    type: "img",
    img: "gps-on-vermont-medical-centre-news.jpg",
    pageLink: "welcome-dr-tharuka-gunasekara",
    title: "Welcome Dr Tharuka Gunasekara",
    contents: [
      "The team are excited to announce that Dr Tharuka Gunasekara will be joining us at GPS On Vermont Medical Centre Wodonga.",
      "Dr. Tharuka has worked across various medicine subspecialties and emergency units in different hospital environments, developing a comprehensive skill set and a deep understanding of patient care. Her special interest lies in enhancing general practice in regional areas, with a focus on improving primary care and the overall health and wellbeing of communities.",
      "Her areas of special interest include general medicine, women's health, elderly care, diabetes management, and mental health. ",

      ".",
      "Call us today to book your appointment",
      "02 6025 2189"
    ],
    date: "2024-09-09",
    enabled: true
  },
  {
    id: "58",
    type: "img",
    img: "beyond-blue-news.jpg",
    pageLink: "beyond-blue-gps-on-vermont",
    title: "GPs on Vermont Medical Centre is dedicated in supporting high quality health care for the community.",
    contents: [
      "This month we have focused on raising the awareness towards mental health and providing ways we can support our community. ",
      "We have partnered up with Beyond Blue in raising funds towards this cause.",
      "We have a donation box set up at reception if you wish to donate. ",
      "Raffle tickets are available to go in the draw to win the amazing hamper. Items proudly donated from by our locally community. ",
      "https://www.facebook.com/MixxNutritionStudio",
      "https://www.facebook.com/butcheronrise",
      "https://www.facebook.com/valentinesbakehouse",
      "https://www.facebook.com/profile.php?id=100064075750435",
      "https://www.facebook.com/profile.php?id=100057161991600",
      "Along with heaps of items to enjoy with your family donated from the team at GPs On Vermont."
    ],
    date: "2024-10-20",
    displayBookingButton: false,
    displayBookingButtonDrId: "",
    disableReadMore : false,
    enabled: true
  },
  {
    id: "59",
    type: "img",
    img: "dr-tharuka2-news.jpg",
    pageLink: "appointments-available-dr-tharuka-gunasekara",
    title: "Appointments Available with Dr Tharuka Gunasekara",
    contents: [
      "✅ Bulk Billing. Call us today to book your appointment 02 6025 2189, Or click the button below:",
      " •	 "
    ],
    date: "2024-10-21",
    displayBookingButton: true,
    displayBookingButtonDrId: "dr-tharuka-gunasekara",
    disableReadMore : true,
    enabled: true
  },
  {
    id: "60",
    type: "img",
    img: "medicare-news.jpg",
    pageLink: "bulk-billilng-appointments-available-2024-12-19",
    title: "Bulk Billing Appointments available!",
    contents: [
      "We are proud to offer some bulk billing services to all our patients. No out-of-pocket costs for your consultation",
      "Call us at 0260 252189 or Click the Book Appointment button above",
      " •	"
    ],
    date: "2024-12-19",
    displayBookingButton: true,
    enabled: true
  },
  {
    id: "61",
    type: "img",
    img: "xmas-tree-news.jpg",
    pageLink: "merry-festive-season-2024-news",
    title: "Wishing You A Very Merry Festive Season And A Happy New Year!",
    contents: [
      "Wow, it’s been another interesting year! One full of challenges, changes, adaptability and resilience.",
      "Despite the challenges this past year seems to have flown by, especially in these last few months, but we couldn’t have gotten here without your support. Thank you for your patience and for bearing with us.",
      "We are looking forward to this coming year and seeing what it will bring. We are hoping and have our fingers crossed for a much smoother and peaceful year.",
      "Thank you for allowing us to be a part of your health team and to serve the wonderful Albury Wodonga community.",
      "We are looking forward to continuing to be a part of your healthcare journey in 2025.",
      "We wish you and your loved ones good health and happiness this holiday season, new year and beyond!.",
      "Stay well, stay safe and have fun!.",
      "-",
      "Best wishes and sincere thanks from the team at GPs on Vermont!."
    ],
    date: "2024-12-22",
    enabled: true
  },
  {
    id: "62",
    type: "img",
    img: "gps-on-vermont-medical-centre-news.jpg",
    pageLink: "welcome-dr-sampath-aluthge",
    title: "Welcome Dr Sampath Aluthge",
    contents: [
      "The team are excited to announce that Dr Sampath Aluthge will be joining us at GPS On Vermont Medical Centre Wodonga.",
      "With extensive experience as a General Practitioner, he excels in managing acute and chronic medical conditions, mental health challenges, and surgical issues while working at leading hospitals in Sri Lanka.",
      "His areas of special interest include general medicine, mental health, minor surgical procedures, and management of non-communicable diseases. ",

      ".",
      "Call us today to book your appointment",
      "02 6025 2189"
    ],
    date: "2025-02-08",
    enabled: true
  },
  {
    id: "63",
    type: "img",
    img: "gps-on-vermont-medical-centre-news.jpg",
    pageLink: "appointments-available-dr-sampath-aluthge",
    title: "Appointments Available with Dr Sampath Aluthge",
    contents: [
      "✅ Bulk Billing. Call us today to book your appointment 02 6025 2189, Or click the button below:",
      " •	 "
    ],
    date: "2025-02-09",
    displayBookingButton: true,
    displayBookingButtonDrId: "dr-sampath-aluthge",
    disableReadMore : true,
    enabled: true
  },
  {
    id: "64",
    type: "img",
    img: "gps-on-vermont-medical-centre-news.jpg",
    pageLink: "appointments-available-dr-virajinee-rathnayake",
    title: "Appointments Available with Dr Virajinee Rathnayake",
    contents: [
      "✅ Bulk Billing. Call us today to book your appointment 02 6025 2189, Or click the button below:",
      " •	 "
    ],
    date: "2025-02-17",
    displayBookingButton: true,
    displayBookingButtonDrId: "dr-virajinee-rathnayake",
    disableReadMore : true,
    enabled: true
  },
  {
    id: "65",
    type: "img",
    img: "vaccine-shot-1-news.jpg",
    pageLink: "covid-vaccine-boosters-available-2025-2",
    title: "Covid vaccine boosters available",
    contents: [
      "✅ Boosters are now available for patients if six months have passed since their last vaccine.",
      "✅ Call us if you’re unsure when your last vaccine was administered: 02 6025 2189",
      "✅ Find more information at: https://www.health.gov.au/our-work/covid-19-vaccines/getting-your-vaccination"
    ],
    date: "2025-02-26",
    displayBookingButton: false,
    disableReadMore : false,
    enabled: true
  },
  {
    id: "100",
    type: "img",
    img: "alzheimers-dementia-news.jpg",
    pageLink: "how-much-do-you-understand-about-alzheimers-and-dementia-news",
    title: "",
    contents: [
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "1)  ",
      "2)  ",
      "3)  ",
      "4)  ",
      "5)  ",
      "6)  ",
      "7)  ",
      "8)  ",
      "9)  ",
      "10) ",
      "",
      "",
      "",
      "",
      "",
      ""
    ],
    date: "2021-11-05",
    enabled: false
  }
];

export default news;
